import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import FadeIn from 'react-fade-in';
import $ from 'jquery';
import * as Bootstrap from 'bootstrap';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as Config from './../config';
import { } from './../store/userSlice';

import ProductCard from './../components/ProductCard';
import BottomBar from './../components/BottomBar';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = { product: { list: [], val: null } };

    this.getProfileCompletion = this.getProfileCompletion.bind(this);
  }

  async componentDidMount() {
    $('#banner-carousel').on('slide.bs.carousel', function(e) {
      let currentIndex = $(e.relatedTarget).index();
      $(".home-banner-indicator").each(function(index) {
        $(this).removeClass("active");
        if(currentIndex == index) $(this).addClass("active");
      });
    });

    $('.carousel').on('touchstart', function(event){
      const xClick = event.originalEvent.touches[0].pageX;
      $(this).one('touchmove', function(event){
        const xMove = event.originalEvent.touches[0].pageX;
        const sensitivityInPx = 5;

        if(Math.floor(xClick - xMove) > sensitivityInPx) {
          $(this).carousel('next');
        } else if(Math.floor(xClick - xMove) < -sensitivityInPx) {
          $(this).carousel('prev');
        }
      });
      $(this).on('touchend', function(){
        $(this).off('touchmove');
      });
    });
    try {
      let formData = new FormData();
      formData.append('limit', 10);
      let response = await axios.post(`${Config.API_URL}/product/get-product-list`, formData);
      let data = response.data;
      if(data.status) {
        this.setState({
          product: { list: data.data }
        });
      }
    } catch(error) {
      await Swal.fire('Failed', 'Unable to fetch product list', 'error');
    }
  }

  getProfileCompletion() {
    let score = 0;
    if(this.props.user.value == null) return 0;
    if(this.props.user.value.gender != null) score++;
    if(this.props.user.value.dob != null) score++;
    if(this.props.user.value.goal != null) score++;
    if(this.props.user.value.height != null) score++;
    if(this.props.user.value.weight != null) score++;
    return score == 0 ? score : (score / 5 * 100);
  }

  render() {
    return (
      <div className="flex-grow-1 d-flex flex-column h-100">
        <div className="flex-grow-1 overflow">
          <div className="home-banner">
            <div id="banner-carousel" className="carousel slide" data-ride="carousel">
              <div className="carousel-inner">
              {
                [...Array(3)].map((e, i) => <div key={i} className={`carousel-item ${(i == 0 ? 'active' : '')}`}>
                <div className="d-flex flex-md-row">
                  <div className="left align-self-center">
                    <h5>Sebuah Promo</h5>
                    <h1>Buy 3 <br/> Get 4</h1>
                  </div>
                  <div className="middle"></div>
                  <div className="right align-self-center">
                    <img src={`${Config.BASE_URL}/assets/img/home-banner-img.png`} alt=""/>
                  </div>
                </div>
              </div>)
              }
              </div>
            </div>
          </div>
          <div className="home-promo">
            <div className="container">
              <div className="d-flex first-flex">
                <div className="left flex-grow-1">
                  <span data-target="#banner-carousel" data-slide-to="0" className="home-banner-indicator clickable active"></span>
                  <span data-target="#banner-carousel" data-slide-to="1" className="home-banner-indicator clickable"></span>
                  <span data-target="#banner-carousel" data-slide-to="2" className="home-banner-indicator clickable"></span>
                </div>
                <div className="right flex-grow-1">
                  <a href="#0" className="clickable">Lihat semua promo</a>
                </div>
              </div>
              <div className="profile-status">
                {this.props.user.value != null && <><div className="status">Kelengkapan Profile: {this.getProfileCompletion()}%</div>
                <div className="progress">
                  <div className="progress-bar" style={{width: `${this.getProfileCompletion()}%`}}></div>
                </div>
                <div className="profile-banner">
                  <div className="wrapper">
                    <div className="d-flex justify-content-between">
                      <div className="content align-self-center">
                        {this.getProfileCompletion() < 100 && <div className="content-title">Lengkapi profilemu yuk !</div>}
                        {this.getProfileCompletion() == 100 && <div className="content-title">Profile mu sudah lengkap !</div>}
                        <div className="text">Kami bisa merekomendasikan makanan & minuman yang cocok denganmu</div>
                      </div>
                      <div className="img align-self-end"><img src={`${Config.BASE_URL}/assets/img/profile-banner-right.png`} alt=""/></div>
                    </div>
                  </div>
                </div></>}
              </div>
              <div className="voucher clickable">
                <div className="d-flex">
                  <div className="left flex-grow-1">
                    <div className="d-flex">
                      <div className="left align-self-center">
                        <img src={`${Config.BASE_URL}/assets/img/voucher-icon.png`} alt=""/>
                      </div>
                      <div className="middle"></div>
                      <div className="right">
                        <div className="label">Voucher Saya</div>
                        <div className="value">7 Voucher</div>
                      </div>
                    </div>
                  </div>
                  <div className="right flex-grow-1"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="categories">
            <div className="container">
              <div className="d-flex justify-content-around">
                <Link to="/product-list?category=food">
                  <div className="category clickable">
                    <div className="logo">
                      <img src={`${Config.BASE_URL + '/assets/img/food-icon.png'}`} alt=""/>
                    </div>
                    <div className="label">Food</div>
                  </div>
                </Link>
                <Link to="/product-list?category=dessert">
                  <div className="category clickable">
                    <div className="logo">
                      <img src={`${Config.BASE_URL + '/assets/img/dessert-icon.png'}`} alt=""/>
                    </div>
                    <div className="label">Dessert</div>
                  </div>
                </Link>
                <Link to="/product-list?category=drink">
                  <div className="category clickable">
                    <div className="logo">
                      <img src={`${Config.BASE_URL + '/assets/img/drink-icon.png'}`} alt=""/>
                    </div>
                    <div className="label">Drink</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="all-product">
            <div className="container">
              <div className="header">
                <div className="d-flex justify-content-between">
                  <div className="left"><h3>Semua Makanan & Minuman</h3></div>
                  <div className="right align-self-center"><div className="label clickable"><Link to="/product-list">Lihat Semua</Link></div></div>
                </div>
              </div>
              <div className="content">
                <FadeIn>
                  <div className="d-flex">
                    {this.state.product.list.map((product, index) => <ProductCard key={index} product={product}></ProductCard>)}
                  </div>
                </FadeIn>
              </div>
            </div>
          </div>
        </div>
        <BottomBar></BottomBar>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  }
}, (dispatch) => {
  return {

  }
})(withRouter(Home));
