import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as Config from './../config';
import * as API from './../API';
import { getCart, setCart } from './../store/userSlice';
import { GMapify } from  'g-mapify';
import  'g-mapify/dist/index.css';

class SelectAddress extends React.Component {
  constructor(props) {
    super(props);
    if(this.props.user.value == null) this.props.history.replace('/sign-in');
    this.onMapSelect = this.onMapSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);

    this.state = {
      address: 'Jl. Raya Mangga Besar No.20a, RT.14/RW.9, Maphar, Kec. Taman Sari, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11160, Indonesia',
      latitude: -62.149814,
      longitude: 106.819597,
      placeID: 'ChIJ96JXP-L1aS4R5pGkI-NfSGQ',
      note: '',
      showMap: false,
    };
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    this.state[name] = value;
    this.setState({ [name]: this.state[name] });
  }

  onMapSelect(status, data) {
    if(status) {
      this.setState({
        address: data.formatted_address,
        latitude: data.geometry.location.lat,
        longitude: data.geometry.location.lng,
        placeID: data.place_id,
      });
    }
  }

  async componentDidMount() {
    if(this.props.match.params.id == null) {
      if(navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }, () => this.setState({
            showMap: true,
          }));
        });
      } else {
        console.log("Location not allowed");
      }
    } else {
      let address = await API.getAddress(this.props.match.params.id);
      console.log(address);
      this.setState({
        address: address.address,
        latitude: Number(address.latitude),
        longitude: Number(address.longitude),
        placeID: address.location_id,
        note: address.note,
      }, () => this.setState({
        showMap: true,
      }));
    }
  }

  async submit() {
    if(this.state.isLoading) return;
    this.state.isLoading = true;
    let status;
    if(this.props.match.params.id == null) status = await API.addAddress(this.props.user.value.user_id, this.state.address, this.state.latitude, this.state.longitude, this.state.placeID, this.state.note);
    else status = await API.editAddress(this.props.match.params.id, this.state.address, this.state.latitude, this.state.longitude, this.state.placeID, this.state.note);
    if(status) {
      this.props.history.replace("/select-address");
    } else {
      await Swal.fire('Failed', 'Gagal menambahkan alamat', 'error');
    }
    this.state.isLoading = false;
  }

  render() {
    return (
      <>
      <div className="select-location flex-grow-1">
        <div className="map flex-grow-1">
          <div className="return">
            <div className="container">
              <div onClick={() => this.props.history.replace('/select-address')} className="clickable arrow">
                <i className="fa fa-arrow-circle-left"></i>
              </div>
            </div>
          </div>
          {this.state.showMap && <GMapify
            appKey={Config.MAP_API}
            hasSearch={true}
            mapOptions={{
              fullScreenControl: true
            }}
            lat={this.state.latitude}
            lng={this.state.longitude}
            onSelect={this.onMapSelect}
          />}
        </div>
        <div className="note">
          <div className="container">
            <h3>{this.state.address}</h3>
            <textarea name="note" onChange={this.handleChange} value={this.state.note} rows="1" placeholder="Catatan"></textarea>
            <div className="footnote">Tulis No. Rumah, Blok, RT/RW, dll.</div>
            <div className="action"><button onClick={this.submit} type="button">Simpan Lokasi</button></div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  }
}, (dispatch) => {
  return {
    getCart: () => dispatch(getCart()),
    setCart: (data) => dispatch(setCart(data)),
  }
})(withRouter(SelectAddress));
