import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as Config from './../config';
import * as API from './../API';
import TitleBar from './../components/TitleBar';
import { getCart, setCart, getCoupon, setCoupon } from './../store/userSlice';

class SelectCoupon extends React.Component {
  constructor(props) {
    super(props);
    if(this.props.user.value == null) this.props.history.replace('/sign-in');
    this.selectCoupon = this.selectCoupon.bind(this);

    this.state = {
      coupon: {
        list: [], val: null,
      }
    };
  }

  async componentDidMount() {
    if(this.props.user.value == null) return;
    this.state.coupon.list = await API.getCouponList();
    console.log(this.state.coupon);
    this.setState({ coupon: this.state.coupon });
    this.props.getCoupon();
  }

  selectCoupon(coupon) {
    this.props.setCoupon(coupon);
    this.props.history.replace('/cart');
  }

  render() {
    return (
      <div className="flex-grow-1 d-flex flex-column overflow">
        <div className="select-coupon">
          <TitleBar title="Pilih Kupon" redirect="/cart" top="30%"></TitleBar>

          <div className="coupon-list">
            <div className="container">
              {this.state.coupon.list.length == 0 && <div className="text-center"><small className="text-secondary"><em>Belum ada kupon tersedia</em></small></div>}
              {this.state.coupon.list.map((item, index) => (
                <FadeIn key={index}>
                  <div onClick={() => this.selectCoupon(item)} className="item clickable">
                    <h4>{item.name}</h4>
                    <p>{item.description}</p>
                  </div>
                </FadeIn>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  }
}, (dispatch) => {
  return {
    getCart: () => dispatch(getCart()),
    setCart: (data) => dispatch(setCart(data)),
    getCoupon: () => dispatch(getCoupon()),
    setCoupon: (data) => dispatch(setCoupon(data)),
  }
})(withRouter(SelectCoupon));
