import axios from 'axios';
import * as Config from './config';

export const getCart = async (userID) => {
  let formData = new FormData();
  formData.append('userID', userID);
  try {
    let response = await axios.post(`${Config.API_URL}/product/get-cart-list`, formData);
    let data = response.data;
    if(data.status) {
      return data.data;
    } else {
      console.log(data.msg);
    }
  } catch(error) {
    console.log(error);
  }
  return [];
}

export const setCart = async (userID, productID) => {
  let formData = new FormData();
  formData.append('userID', userID);
  formData.append('productID', productID);
  try {
    let response = await axios.post(`${Config.API_URL}/product/add-to-cart`, formData);
    let data = response.data;
    if(data.status) {
      return true;
    } else {
      console.log(data.msg);
    }
  } catch(error) {
    console.log(error);
  }
  return false;
}

export const removeFromCart = async (userID, productID) => {
  let formData = new FormData();
  formData.append('userID', userID);
  formData.append('productID', productID);
  try {
    let response = await axios.post(`${Config.API_URL}/product/remove-from-cart`, formData);
    let data = response.data;
    if(data.status) {
      return true;
    } else {
      console.log(data.msg);
    }
  } catch(error) {
    console.log(error);
  }
  return false;
}

export const updateCartQty = async (userID, productID, qty) => {
  let formData = new FormData();
  formData.append('userID', userID);
  formData.append('productID', productID);
  formData.append('qty', qty);
  try {
    let response = await axios.post(`${Config.API_URL}/product/update-cart-qty`, formData);
    let data = response.data;
    if(data.status) {
      return true;
    } else {
      console.log(data.msg);
    }
  } catch(error) {
    console.log(error);
  }
  return false;
}

export const getAddress = async (id) => {
  let formData = new FormData();
  formData.append('id', id);
  try {
    let response = await axios.post(`${Config.API_URL}/get-address`, formData);
    let data = response.data;
    if(data.status) {
      return data.data;
    } else {
      console.log(data.msg);
    }
  } catch(error) {
    console.log(error);
  }
  return [];
}

export const getAddressList = async (userID) => {
  let formData = new FormData();
  formData.append('userID', userID);
  try {
    let response = await axios.post(`${Config.API_URL}/get-user-address`, formData);
    let data = response.data;
    if(data.status) {
      return data.data;
    } else {
      console.log(data.msg);
    }
  } catch(error) {
    console.log(error);
  }
  return [];
}

export const getCouponList = async () => {
  let formData = new FormData();
  try {
    let response = await axios.post(`${Config.API_URL}/get-coupon-list`, formData);
    let data = response.data;
    if(data.status) {
      return data.data;
    } else {
      console.log(data.msg);
    }
  } catch(error) {
    console.log(error);
  }
  return [];
}

export const addAddress = async (userID, address, latitude, longitude, locationID, note) => {
  let formData = new FormData();
  formData.append('id', userID);
  formData.append('address', address);
  formData.append('latitude', latitude);
  formData.append('longitude', longitude);
  formData.append('locationID', locationID);
  formData.append('note', note);
  try {
    let response = await axios.post(`${Config.API_URL}/add-user-address`, formData);
    let data = response.data;
    if(data.status) {
      return true;
    } else {
      console.log(data.msg);
    }
  } catch(error) {
    console.log(error);
  }
  return false;
}

export const editAddress = async (id, address, latitude, longitude, locationID, note) => {
  let formData = new FormData();
  formData.append('addressID', id);
  formData.append('address', address);
  formData.append('latitude', latitude);
  formData.append('longitude', longitude);
  formData.append('locationID', locationID);
  formData.append('note', note);
  try {
    let response = await axios.post(`${Config.API_URL}/edit-user-address`, formData);
    let data = response.data;
    if(data.status) {
      return true;
    } else {
      console.log(data.msg);
    }
  } catch(error) {
    console.log(error);
  }
  return false;
}

export const getCourierList = async (supplierID, longitude, latitude) => {
  let formData = new FormData();
  formData.append('supplierId', supplierID);
  formData.append('longitude', longitude);
  formData.append('latitude', latitude);
  try {
    let response = await axios.post(`${Config.API_URL}/get-courier-list`, formData);
    let data = response.data;
    if(data.status) {
      return data.data;
    } else {
      console.log(data.msg);
    }
  } catch(error) {
    console.log(error);
  }
  return [];
}
