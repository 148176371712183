import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as Config from './../config';
import { getLogin } from './../store/userSlice';

class BottomBar extends React.Component {
  constructor(props) {
    super(props);

    this.getCartQty = this.getCartQty.bind(this);
  }

  getCartQty() {
    let qty = 0;
    for(let i=0;i<this.props.user.cart.length;i++) {
      let item = this.props.user.cart[i];
      qty += Number(item.cart_qty);
    }
    return qty;
  }

  render() {
    return (
    <div className="wrapper-bottom-nav">
      <div className="bottom-nav">
        <div className="d-flex">
          <Link to="/" className="flex-grow-1">
            <div className={`tab ${this.props.match.url == '/' && 'tab-active'}`}>
              <div className="d-flex flex-column">
                <div className="icon clickable"><i className="fa fa-home"></i></div>
                <div className="label">Home</div>
              </div>
            </div>
          </Link>
          <Link to="/history" className="flex-grow-1">
            <div className={`tab ${this.props.match.url == '/history' && 'tab-active'}`}>
              <div className="d-flex flex-column">
                <div className="icon clickable"><i className="fa fa-clock-o"></i></div>
                <div className="label">History</div>
              </div>
            </div>
          </Link>
          <Link to="/cart" className="flex-grow-1">
            <div className={`tab ${this.props.match.url == '/cart' && 'tab-active'}`}>
              <div className="d-flex flex-column">
                <div className="icon clickable">
                  <i className="fa fa-shopping-cart"></i>
                  {this.props.user.cart.length > 0 && <div className="cart-qty"><div className="d-flex justify-content-center"><div>{new Intl.NumberFormat().format(this.getCartQty())}</div></div></div>}
                </div>
                <div className="label">Keranjang</div>
              </div>
            </div>
          </Link>
          {this.props.user.value != null ? <Link to="/profile" className="flex-grow-1">
            <div className={`tab ${this.props.match.url == '/profile' && 'tab-active'}`}>
              <div className="d-flex flex-column">
                <div className="icon clickable"><i className="fa fa-user"></i></div>
                <div className="label">Profil</div>
              </div>
            </div>
          </Link> : <Link to="/sign-in" className="flex-grow-1">
            <div className="tab">
              <div className="d-flex flex-column">
                <div className="icon clickable"><i className="fa fa-user"></i></div>
                <div className="label">Sign In</div>
              </div>
            </div>
          </Link>}
        </div>
      </div>
    </div>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  }
}, (dispatch) => {
  return {
    // getLogin: () => dispatch(getLogin()),
  }
})(withRouter(BottomBar));
