import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as Config from './../config';
import { getLogin, logout } from './../store/userSlice';

class TopBar extends React.Component {
  constructor(props) {
    super(props);

    this.openNav = this.openNav.bind(this);
    this.getCartQty = this.getCartQty.bind(this);
  }

  static refresh() {
    this.setState({});
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

  getCartQty() {
    let qty = 0;
    for(let i=0;i<this.props.user.cart.length;i++) {
      let item = this.props.user.cart[i];
      qty += Number(item.cart_qty);
    }
    return qty;
  }

  render() {
    return (
    <div className="nav only-desktop">
      {/* <button className="btn close-nav only-mobile" onClick={this.openNav} style={{fontSize: '30px', cursor: 'pointer'}}><i className="fa fa-navicon"></i></button> */}
      <div className="desktop-nav">
        <div className="container h-100">
          <div className="d-flex justify-content-around h-100">
            <Link to="/" onClick={this.closeNav}><div className="link clickable"><div><i className="fa fa-home"></i> Home</div></div></Link>
            <Link to="/history" onClick={this.closeNav}><div className="link clickable"><div><i className="fa fa-clock-o"></i> History</div></div></Link>
            <Link to="/cart" onClick={this.closeNav}><div className="link clickable"><div><i className="fa fa-shopping-cart"></i> Cart {this.props.user.cart.length > 0 && <div className="cart-qty"><div className="d-flex justify-content-center"><div>{new Intl.NumberFormat().format(this.getCartQty())}</div></div></div>}</div></div></Link>
            {this.props.user.value != null && <Link to="/profile" onClick={this.closeNav}><div className="link clickable"><div><i className="fa fa-user"></i> Profile</div></div></Link>}
            {this.props.user.value == null && <Link to="/sign-in" onClick={this.closeNav}><div className="link clickable"><div><i className="fa fa-user"></i> Sign In</div></div></Link>}
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  }
}, (dispatch) => {
  return {
    // getLogin: () => dispatch(getLogin()),
  }
})(withRouter(TopBar));
