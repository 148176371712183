import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";

class TitleBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="title">
        <div className="container">
          <div className="d-flex">
            <div onClick={() => this.props.history.replace(this.props.redirect)} className="back clickable align-self-center" style={{top: this.props.top == null ? '40%' : this.props.top}}><i className="fa fa-chevron-left"></i></div>
            <div className="align-self-center flex-grow-1"><h3>{this.props.title}</h3></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TitleBar);
