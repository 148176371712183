import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import * as Config from './../config';
import * as API from './../API';
import { setCart } from './../store/userSlice';
import { connect } from 'react-redux';

import ProductCard from './../components/ProductCard';
import ReviewCard from './../components/ReviewCard';

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.setProductTab = this.setProductTab.bind(this);
    this.getProductReview = this.getProductReview.bind(this);
    this.addToCart = this.addToCart.bind(this);

    this.state = {
      isLoading: false,
      product: null,
      productTab: { list: ['DESC', 'BENEFIT'], val: ['DESC'] },
    };
  }

  async componentDidMount() {
    try {
      let formData = new FormData();
      formData.append('itemID', this.props.match.params.id);
      let response = await axios.post(`${Config.API_URL}/product/get-product`, formData);
      let data = response.data;
      if(data.status) {
        this.setState({ product: data.data });
      }
    } catch(error) {
      await Swal.fire('Failed', 'Unable to fetch product list', 'error');
    }
    if(this.state.product != null) this.getProductReview();
  }

  async getProductReview() {
    try {
      let formData = new FormData();
      formData.append('productID', this.state.product.item_id);
      let response = await axios.post(`${Config.API_URL}/product/get-product-review`, formData);
      let data = response.data;
      if(data.status) {
        this.state.product.review = data.data;
        this.state.product.total_review = data.total;
        this.setState({ product: this.state.product });
      }
    } catch(error) {
      await Swal.fire('Failed', 'Unable to fetch product list', 'error');
    }
  }

  async addToCart() {
    if(this.state.isLoading) return;
    this.state.isLoading = true;
    let status = await API.setCart(this.props.user.value.user_id, this.state.product.item_id);
    if(status) {
      await Swal.fire('Success', 'Produk berhasil ditambahkan ke dalam keranjang', 'success');
      let cart = await API.getCart(this.props.user.value.user_id);
      this.props.setCart(cart);
    } else {
      await Swal.fire('Failed', 'Gagal menambahkan produk ke dalam keranjang', 'error');
    }
    this.state.isLoading = false;
  }

  setProductTab(tab) {
    this.setState({ productTab: { val: tab }});
  }

  render() {
    return (
      <>
      <div className="flex-grow-1 d-flex flex-column overflow">
        <div className="product-detail">
          <div className="product-img">
            <div className="return">
              <div className="container">
                <div onClick={() => this.props.history.goBack()} className="clickable arrow">
                  <i className="fa fa-arrow-circle-left"></i>
                </div>
              </div>
            </div>
            {this.state.product != null && <FadeIn><img src={`${this.state.product.image_path}`} alt=""/></FadeIn>}
          </div>
          {this.state.product != null &&
          <div className="top-info">
            <div className="container">
              <FadeIn>
                <div className="supplier-name">{this.state.product.supplier_name}</div>
                <div className="product-name">{this.state.product.name}</div>
                <div className="size">50 ml</div>
                <div className="price">Rp {new Intl.NumberFormat().format(this.state.product.price)}</div>
                {/* <div className="rating">
                  {this.state.product.star != null && parseFloat(this.state.product.star) < 1 && parseFloat(this.state.product.star) > 0 ? <i className="fa fa-star-half-o"></i> : parseFloat(this.state.product.star) > 0 ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i>}
                  {this.state.product.star != null && parseFloat(this.state.product.star) < 2 && parseFloat(this.state.product.star) > 1 ? <i className="fa fa-star-half-o"></i> : parseFloat(this.state.product.star) > 1 ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i>}
                  {this.state.product.star != null && parseFloat(this.state.product.star) < 3 && parseFloat(this.state.product.star) > 2 ? <i className="fa fa-star-half-o"></i> : parseFloat(this.state.product.star) > 2 ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i>}
                  {this.state.product.star != null && parseFloat(this.state.product.star) < 4 && parseFloat(this.state.product.star) > 3 ? <i className="fa fa-star-half-o"></i> : parseFloat(this.state.product.star) > 3 ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i>}
                  {this.state.product.star != null && parseFloat(this.state.product.star) < 5 && parseFloat(this.state.product.star) > 4 ? <i className="fa fa-star-half-o"></i> : parseFloat(this.state.product.star) > 4 ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i>}
                </div> */}
              </FadeIn>
            </div>
          </div>}
          {this.state.product != null &&
          <div className="product-description">
            <div className="container">
              <div className="navigation">
                <div className="d-flex">
                  <div onClick={() => this.setProductTab('DESC')} className={`label ${this.state.productTab.val == 'DESC' ? 'active' : ''} flex-grow-1 clickable`}>Deskripsi Produk</div>
                  <div onClick={() => this.setProductTab('BENEFIT')} className={`label ${this.state.productTab.val == 'BENEFIT' ? 'active' : ''} flex-grow-1 clickable`}>Manfaat</div>
                </div>
              </div>
              {this.state.productTab.val == 'DESC' && <FadeIn><div className="content">{this.state.product.description}</div></FadeIn>}
              {this.state.productTab.val == 'BENEFIT' && <FadeIn><div className="content">{this.state.product.benefit}</div></FadeIn>}
            </div>
          </div>}
          {this.state.product != null &&
          <div className="product-supplier">
            <div className="container">
              <h3>Tentang Penjual</h3>
              <FadeIn>
                <div className="d-flex">
                  <div className="logo"><img src={`${Config.API_URL}/${this.state.product.supplier_logo}`} alt=""/></div>
                  <div style={{minWidth: '8px'}}></div>
                  <div className="align-self-center">
                    <div className="supplier-name">{this.state.product.supplier_name}</div>
                    <div className="supplier-location">{this.state.product.supplier_address}</div>
                  </div>
                </div>
              </FadeIn>
            </div>
          </div>}
          {/* this.state.product != null &&
          <div className="product-review">
            <div className="container">
              <div className="d-flex mb-2">
                <div className="flex-grow-1"><h3>Ulasan Pelanggan</h3></div>
                <div><i className="fa fa-star"></i></div>
                <div className="score">{new Intl.NumberFormat().format(this.state.product.star)}</div>
                {this.state.product.review != null && <div className="review-qty align-self-center">{new Intl.NumberFormat().format(this.state.product.total_review)} Ulasan</div>}
              </div>
              {this.state.product.review != null && this.state.product.review.map((item, index) =>
              <FadeIn key={index}>
                <ReviewCard review={item}></ReviewCard>
                {(index + 1) < this.state.product.review.length && <div className="review-separator"></div>}
              </FadeIn>)}
              {this.state.product.review != null && <div className="see-all clickable">Lihat lebih banyak ({new Intl.NumberFormat().format(this.state.product.total_review)} ulasan)</div>}
            </div>
          </div> */}
          <div className="action-menu">
            <div className="container">
              <div className="d-flex">
                <div className="chat-button">
                  <button className="button"><img src={`${Config.BASE_URL}/assets/img/chat-icon.png`} alt=""/></button>
                </div>
                <div style={{minWidth: '8px'}}></div>
                <div className="buy-button flex-grow-1">
                  <button className="button">Beli</button>
                </div>
                <div style={{minWidth: '8px'}}></div>
                <div className="cart-button flex-grow-1">
                  <button onClick={this.addToCart} className="button">+ Keranjang</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  }
}, (dispatch) => {
  return {
    setCart: (data) => dispatch(setCart(data)),
  }
})(withRouter(ProductDetail));
