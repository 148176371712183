import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as Config from './../config';
import { getLogin, logout } from './../store/userSlice';
import FullWidthIconButton from './../components/FullWidthIconButton';
import BottomBar from './../components/BottomBar';

class History extends React.Component {
  constructor(props) {
    super(props);
    if(this.props.user.value == null) this.props.history.replace('/sign-in');

    this.state = {

    };
  }

  async componentDidMount() {

  }

  render() {
    return (
      <div className="d-flex flex-column h-100 flex-grow-1">
        <div className="flex-grow-1 overflow">
          <div className="transaction-history">
            <h3>History Transaksi</h3>
            {this.props.user.value == null && <Redirect to="/sign-in"></Redirect>}

            <div className="tab-list">
              <div className="tab-item active">Semua</div>
              <div className="tab-item">Menunggu Pembayaran</div>
              <div className="tab-item">Menunggu Konfirmasi</div>
              <div className="tab-item">Dalam Proses</div>
              <div className="tab-item">Sedang Dikirim</div>
              <div className="tab-item">Diterima</div>
              <div className="tab-item">Batal</div>
            </div>
          </div>
        </div>

        <BottomBar></BottomBar>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  }
}, (dispatch) => {
  return {
    getLogin: () => dispatch(getLogin()),
    logout: () => dispatch(logout()),
  }
})(withRouter(History));
