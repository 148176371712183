import { createSlice } from '@reduxjs/toolkit';
import { reactLocalStorage } from 'reactjs-localstorage';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: null,
    alergic: [],
    cart: [],
    address: null,
    coupon: null,
  },
  reducers: {
    getLogin: state => {
      state.value = reactLocalStorage.getObject('user', null);
    },
    setLogin: (state, action) => {
      reactLocalStorage.setObject('user', action.payload);
      state.value = action.payload;
    },
    logout: state => {
      reactLocalStorage.clear();
      state.value = null;
      state.alergic = [];
      state.cart = [];
    },
    getAlergic: state => {
      state.alergic = reactLocalStorage.getObject('alergic', []);
    },
    setAlergic: (state, action) => {
      reactLocalStorage.setObject('alergic', action.payload);
      state.alergic = action.payload;
    },
    getCart: state => {
      state.cart = reactLocalStorage.getObject('cart', []);
    },
    setCart: (state, action) => {
      reactLocalStorage.setObject('cart', action.payload);
      state.cart = action.payload;
    },
    getAddress: state => {
      state.address = reactLocalStorage.getObject('address', null);
    },
    setAddress: (state, action) => {
      reactLocalStorage.setObject('address', action.payload);
      state.address = action.payload;
    },
    getCoupon: state => {
      state.coupon = reactLocalStorage.getObject('coupon', null);
    },
    setCoupon: (state, action) => {
      reactLocalStorage.setObject('coupon', action.payload);
      state.coupon = action.payload;
    },
  },
});
// reactLocalStorage.clear();

export const {
  setLogin, getLogin, logout, getAlergic, setAlergic, getCart, setCart, getAddress, setAddress, getCoupon, setCoupon
} = userSlice.actions;

export default userSlice.reducer;
