import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import * as Config from './../config';

class ReviewCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let item = this.props.review;
    return (
      <div className="review-card">
        <div className="d-flex">
          <div className="profile-img"><img src={`${Config.API_URL}/${item.profile_img}`} alt=""/></div>
          <div style={{minWidth: '12px'}}></div>
          <div className="profile-info">
            <div className="profile-name">{item.name}</div>
            <div className="rating">
              <div className="d-flex">
                {item.stars != null && parseInt(item.stars) > 0 ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i>}
                {item.stars != null && parseInt(item.stars) > 1 ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i>}
                {item.stars != null && parseInt(item.stars) > 2 ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i>}
                {item.stars != null && parseInt(item.stars) > 3 ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i>}
                {item.stars != null && parseInt(item.stars) > 4 ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i>}
                <div className="review-date align-self-center">{moment(item.review_date).format('DD MMM YYYY HH:mm')}</div>
              </div>
            </div>
            <div className="experience">{item.experience}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ReviewCard);
