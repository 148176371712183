import React from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/id';
import Swal from 'sweetalert2';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as Config from './../config';
import * as API from './../API';
import { reactLocalStorage } from 'reactjs-localstorage';
import { getCart, setCart, getAddress, setAddress, getCoupon } from './../store/userSlice';
import FullWidthIconButton from './../components/FullWidthIconButton';
import TitleBar from './../components/TitleBar';

class Cart extends React.Component {
  constructor(props) {
    super(props);
    if(this.props.user.value == null) this.props.history.replace('/sign-in');
    this.prevMonth = this.prevMonth.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    this.getCartGroup = this.getCartGroup.bind(this);
    this.reduceQty = this.reduceQty.bind(this);
    this.addQty = this.addQty.bind(this);
    this.getCartTotalPrice = this.getCartTotalPrice.bind(this);
    this.handleNoteChange = this.handleNoteChange.bind(this);
    this.fetchCalendar = this.fetchCalendar.bind(this);
    this.setIndex = this.setIndex.bind(this);
    this.isIndexAll = this.isIndexAll.bind(this);
    this.setIndexAll = this.setIndexAll.bind(this);
    this.isScheduleAll = this.isScheduleAll.bind(this);
    this.isScheduleExist = this.isScheduleExist.bind(this);
    this.setProductSchedule = this.setProductSchedule.bind(this);
    this.setProductScheduleToday = this.setProductScheduleToday.bind(this);
    this.getProductSchedule = this.getProductSchedule.bind(this);
    this.setSequence = this.setSequence.bind(this);
    this.getSelectedQty = this.getSelectedQty.bind(this);
    this.saveProductSchedule = this.saveProductSchedule.bind(this);
    this.saveNote = this.saveNote.bind(this);
    this.selectDeliveryMethod = this.selectDeliveryMethod.bind(this);

    this.state = {
      note: ``,
      year: moment().format('YYYY'),
      month: moment().format('MM'),
      cartSchedule: [],
      selectedProduct: null,
      selectedIndex: [],
      isSequence: false,
    };

    this.fetchCalendar();
  }

  fetchCalendar() {
    let currentMonth = moment().set({year: this.state.year, month: this.state.month - 1});
    let weeks = [];
    let rows = [];
    for(let i=1;i<=currentMonth.daysInMonth();i++) {
      let currentDay = moment(currentMonth).set({date: i});
      let dayOfWeek = currentDay.day();
      rows[dayOfWeek] = currentDay;
      if(dayOfWeek == 6 || currentMonth.daysInMonth() == i) {
        weeks.push(rows);
        rows = [];
      }
    }
    return weeks;
  }

  setIndex(index) {
    if(this.state.selectedIndex.indexOf(index) == -1) this.state.selectedIndex.push(index);
    else this.state.selectedIndex.splice(this.state.selectedIndex.indexOf(index), 1);
    this.state.selectedIndex.sort();
    this.setState({ selectedIndex: this.state.selectedIndex });
  }

  isIndexAll() {
    return this.state.selectedIndex.length == parseInt(this.state.selectedProduct.cart_qty);
  }

  isScheduleAll() {
    let schedule = this.getProductSchedule(this.state.selectedProduct.id);
    for(let i=0;i<parseInt(this.state.selectedProduct.cart_qty);i++) {
      if(schedule.schedule[i] == null) return false;
    }
    return true;
  }

  isScheduleExist() {
    let schedule = this.getProductSchedule(this.state.selectedProduct.id);
    for(let i=0;i<parseInt(this.state.selectedProduct.cart_qty);i++) {
      if(schedule.schedule[i] != null) return true;
    }
    return false;
  }

  setIndexAll() {
    if(!this.isIndexAll()) {
      this.state.selectedIndex = [];
      for(let i=0;i<parseInt(this.state.selectedProduct.cart_qty);i++) {
        this.state.selectedIndex.push(parseInt(i));
      }
    } else {
      this.state.selectedIndex = [];
    }
    this.setState({selectedIndex: this.state.selectedIndex});
  }

  setSequence() {
    this.state.isSequence = !this.state.isSequence;
    this.setState({isSequence: this.state.isSequence});
  }

  getProductSchedule(cartID) {
    for(let i=0;i<this.state.cartSchedule.length;i++) {
      let item = this.state.cartSchedule[i];
      if(item.id == cartID) return item;
    }
    return null;
  }

  setProductSchedule(date) {
    if(moment().isAfter(date, 'day')) return;
    for(let i=0;i<this.state.cartSchedule.length;i++) {
      let item = this.state.cartSchedule[i];
      if(item.id == this.state.selectedProduct.id) {
        if(this.state.isSequence) {
          for(let s=0;s<this.state.selectedIndex.length;s++) item.schedule[this.state.selectedIndex[s]] = moment(date).add(s, 'day');
        } else {
          for(let s=0;s<this.state.selectedIndex.length;s++) item.schedule[this.state.selectedIndex[s]] = date;
        }
      }
    }
    this.setState({cartSchedule: this.state.cartSchedule, selectedIndex: []});
  }

  setProductScheduleToday() {
    for(let i=0;i<this.state.cartSchedule.length;i++) {
      let item = this.state.cartSchedule[i];
      if(item.id == this.state.selectedProduct.id) {
        for(let s=0;s<parseInt(this.state.selectedProduct.cart_qty);s++) item.schedule[s] = moment();
      }
    }
    this.setState({cartSchedule: this.state.cartSchedule, selectedIndex: []});
  }

  getSelectedQty(date) {
    if(date == null) return 0;
    if(this.state.selectedProduct == null) return 0;
    let qty = 0;
    for(let i=0;i<this.state.cartSchedule.length;i++) {
      let item = this.state.cartSchedule[i];
      if(item.id != this.state.selectedProduct.id) continue;
      for(let s=0;s<item.schedule.length;s++) {
        if(item.schedule[s] == null) continue;
        if(moment(item.schedule[s]).isSame(date, 'day')) qty++;
      }
    }
    return qty;
  }

  prevMonth() {
    let month = moment().set({year: this.state.year, month: this.state.month - 1}).add(-1, 'month');
    this.setState({
      year: month.format('YYYY'),
      month: month.format('MM'),
    });
  }

  nextMonth() {
    let month = moment().set({year: this.state.year, month: this.state.month - 1}).add(1, 'month');
    this.setState({
      year: month.format('YYYY'),
      month: month.format('MM'),
    });
  }

  selectDeliveryMethod() {
    for(let i=0;i<this.props.user.cart.length;i++) {
      let item = this.props.user.cart[i];
      let schedule = this.getProductSchedule(item.id);
      for(let s=0;s<parseInt(item.cart_qty);s++) {
        if(schedule.schedule[s] == null) return Swal.fire('Warning', `Jadwal pengiriman untuk produk ${item.name} belum dipilih`, 'warning');
      }
    }
    this.props.history.push('/delivery-method');
  }

  async componentDidMount() {
    if(this.props.user.value == null) return;
    let cart = await API.getCart(this.props.user.value.user_id);
    this.props.setCart(cart);
    let cartSchedule = reactLocalStorage.getObject('cartSchedule', []);
    for(let i=0;i<this.props.user.cart.length;i++) {
      let schedule = [];
      for(let s=0;s<cartSchedule.length;s++) {
        if(cartSchedule[s].id == this.props.user.cart[i].id) {
          schedule = cartSchedule[s].schedule;
        }
      }
      this.state.cartSchedule.push({
        id: this.props.user.cart[i].id,
        schedule: schedule
      });
    }
    this.setState({cartSchedule: this.state.cartSchedule});

    let cartNote = reactLocalStorage.getObject('cartNote', null);
    if(cartNote != null) {
      this.setState({note: cartNote});
    }

    this.props.getAddress();
    if(this.props.user.address == null) {
      let addressList = await API.getAddressList(this.props.user.value.user_id);
      for(let i=0;i<addressList.length;i++) {
        if(addressList[i].is_primary == '1') {
          this.props.setAddress(addressList[i]); break;
        }
      }
    }

    this.props.getCoupon();
  }

  handleNoteChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ note: value });
  }

  getCartGroup() {
    let result = { };
    for(let i=0;i<this.props.user.cart.length;i++) {
      let item = this.props.user.cart[i];
      if(result[item.supplier_id] == null) result[item.supplier_id] = [];
      result[item.supplier_id].push(item);
    }
    return result;
  }

  async reduceQty(item) {
    if(Number(item.cart_qty) <= 1) return;
    if(this.state.isLoading) return;
    this.state.isLoading = true;
    let status = await API.updateCartQty(this.props.user.value.user_id, item.item_id, Number(item.cart_qty) - 1);
    if(status) {
      let cart = await API.getCart(this.props.user.value.user_id);
      this.props.setCart(cart);
    } else {
      await Swal.fire('Failed', 'Gagal mengurangi produk dalam keranjang', 'error');
    }
    this.state.isLoading = false;
  }

  async removeItem(item) {
    if(this.state.isLoading) return;
    let confirm = await Swal.fire({
      title: 'Hapus dari keranjang?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya!'
    });
    if(!confirm.value) return;
    this.state.isLoading = true;
    let status = await API.removeFromCart(this.props.user.value.user_id, item.item_id);
    if(status) {
      let cart = await API.getCart(this.props.user.value.user_id);
      this.props.setCart(cart);
    } else {
      await Swal.fire('Failed', 'Gagal menghapus produk dalam keranjang', 'error');
    }
    this.state.isLoading = false;
  }

  async addQty(item) {
    if(this.state.isLoading) return;
    this.state.isLoading = true;
    let status = await API.updateCartQty(this.props.user.value.user_id, item.item_id, Number(item.cart_qty) + 1);
    if(status) {
      let cart = await API.getCart(this.props.user.value.user_id);
      this.props.setCart(cart);
    } else {
      await Swal.fire('Failed', 'Gagal menambahkan produk ke dalam keranjang', 'error');
    }
    this.state.isLoading = false;
  }

  getCartTotalPrice() {
    let result = 0;
    for(let i=0;i<this.props.user.cart.length;i++) {
      let item = this.props.user.cart[i];
      result += Number(item.cart_qty) * Number(item.price);
    }
    return result;
  }

  saveProductSchedule() {
    reactLocalStorage.setObject('cartSchedule', this.state.cartSchedule);
  }

  saveNote() {
    reactLocalStorage.setObject('cartNote', this.state.note);
  }

  render() {
    return (
      <div className="flex-grow-1 d-flex flex-column overflow">
        <div className="cart">
          <TitleBar title="Pengiriman" redirect="/" top="35%"></TitleBar>

          <div className="delivery-info">
            <div className="container">
              <div className="top d-flex justify-content-between">
                <div className="left align-self-center">Informasi Pengiriman</div>
                <div className="right align-self-center clickable"><Link to="/select-address">Pilih Alamat Lain</Link></div>
              </div>
              {this.props.user.address == null && <div className="bottom"><div className="name"><em>Alamat belum dipilih</em></div></div>}
              {this.props.user.address != null && <div className="bottom">
                <div className="name">{this.props.user.value.name}</div>
                <div className="phone">{this.props.user.value.phone}</div>
                <div className="address">{this.props.user.address.address}</div>
                <div className="type">{this.props.user.address.note}</div>
              </div>}
            </div>
          </div>

          <div className="cart-list">
            <div className="container">
              {this.props.user.cart.length == 0 && <div className="cart-item"><small className="text-secondary"><em>Belum ada produk dikeranjang anda</em></small></div>}
              {Object.keys(this.getCartGroup()).map((supplier, index) => (
                <div key={index} className="cart-item">
                  <div className="supplier d-flex">
                    <div className="icon"><img src={`${Config.API_URL}/${this.getCartGroup()[supplier][0].supplier_logo}`} alt=""/></div>
                    <div style={{minWidth: '6px'}}></div>
                    <div className="name align-self-center">{this.getCartGroup()[supplier][0].supplier_name}</div>
                  </div>
                  <div className="supplier-address">{this.getCartGroup()[supplier][0].supplier_address}</div>
                  {this.getCartGroup()[supplier].map((item, index) => (
                  <FadeIn key={index}>
                    <div className="item-card d-flex">
                      <div className="img"><img src={`${item.image_path}`} alt=""/></div>
                      <div style={{minWidth: '12px'}}></div>
                      <div className="item flex-grow-1">
                        <div className="item-top d-flex justify-content-between">
                          <div className="item-name align-self-center">{item.name}</div>
                          <div style={{minWidth: '12px'}}></div>
                          <div className="item-price">Rp {new Intl.NumberFormat().format(item.price)}</div>
                        </div>
                        <div style={{minHeight: '12px'}}></div>
                        <div className="item-middle d-flex justify-content-between">
                          <div className="item-calories">250 cal</div>
                          <div className="qty">
                            <div className="d-flex">
                              <div onClick={() => this.removeItem(item)} className="delete arrow"><i className="fa fa-trash"></i></div>
                              <div style={{minWidth: '12px'}}></div>
                              <div onClick={() => this.reduceQty(item)} className="arrow clickable"><i className="fa fa-minus-circle"></i></div>
                              <div style={{minWidth: '12px'}}></div>
                              <div className="amount align-self-center">{new Intl.NumberFormat().format(item.cart_qty)}x</div>
                              <div style={{minWidth: '12px'}}></div>
                              <div onClick={() => this.addQty(item)} className="arrow clickable"><i className="fa fa-plus-circle"></i></div>
                            </div>
                          </div>
                        </div>
                        <div style={{minHeight: '8px'}}></div>
                        <div onClick={() => this.setState({selectedProduct: item, selectedIndex: [], isSequence: false})} className="schedule clickable" data-toggle="modal" data-target="#schedule-modal">Atur Jadwal Pengiriman</div>
                      </div>
                    </div>
                  </FadeIn>))}
                  <div className="modal fade" id="schedule-modal" data-backdrop="static">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-body p-0">
                          {this.state.selectedProduct != null && <div className="selected-product d-flex justify-content-between">
                            <div className="left align-self-center">{this.state.selectedProduct.name}</div>
                            <div style={{minWidth: '12px'}}></div>
                            <div className="qty flex-grow-1 align-self-center">{new Intl.NumberFormat().format(this.state.selectedProduct.cart_qty)}x</div>
                            <div className="right align-self-center">
                              {!this.isIndexAll() && <div onClick={this.setIndexAll} className="all clickable">Pilih Semua</div>}
                              {this.isIndexAll() && <div onClick={this.setIndexAll} className="cancel clickable">Batalkan</div>}
                            </div>
                          </div>}
                          {this.state.selectedProduct != null && <div className="product-rows">
                            <div style={{minWidth: '12px'}}></div>
                            {[...Array(parseInt(this.state.selectedProduct.cart_qty))].map((e, index) => (
                              <div key={index} onClick={() => this.setIndex(index)} className={`item ${this.state.selectedIndex.indexOf(index) != -1 ? 'active' : ''} clickable`}>
                                {this.getProductSchedule(this.state.selectedProduct.id).schedule[index] != null && <div className="date-wrapper d-flex flex-column justify-content-center h-100">
                                  {moment(this.getProductSchedule(this.state.selectedProduct.id).schedule[index]).format('MMM')}
                                  <br/>
                                  {moment(this.getProductSchedule(this.state.selectedProduct.id).schedule[index]).format('DD')}
                                </div>}
                                <img src={`${this.state.selectedProduct.image_path}`} alt=""/>
                              </div>
                            ))}
                            <div style={{minWidth: '12px'}}></div>
                          </div>}
                          <div className="select-all d-flex justify-content-between">
                            <div className="left align-self-center">Pilih tanggal pengiriman</div>
                            <div className="right align-self-center">
                              {!this.state.isSequence && <div onClick={this.setSequence} className="concurrent clickable">Atur Berurutan</div>}
                              {this.state.isSequence && <div onClick={this.setSequence} className="cancel clickable">Batalkan</div>}
                            </div>
                          </div>
                          <div className="calendar-head">
                            <div onClick={this.prevMonth} className="left clickable"><img src={`${Config.BASE_URL}/assets/img/keyboard-arrow-left.png`} alt=""/></div>
                            <div className="middle">{moment().set({year: this.state.year, month: this.state.month - 1}).locale('id').format('MMMM YYYY')}</div>
                            <div onClick={this.nextMonth} className="right clickable"><img src={`${Config.BASE_URL}/assets/img/keyboard-arrow-right.png`} alt=""/></div>
                          </div>
                          <hr/>
                          <table className="calendar">
                            <thead>
                              <tr>
                                <th>Min</th>
                                <th>Sen</th>
                                <th>Sel</th>
                                <th>Rab</th>
                                <th>Kam</th>
                                <th>Jum</th>
                                <th>Sab</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.fetchCalendar().map((item, index) => <tr key={index}>
                                <td onClick={() => this.setProductSchedule(item[0])} className={item[0] != null && moment(item[0]).isBefore(moment(), 'day') ? 'past' : 'clickable'}>{item[0] == null ? '' : <div className={`day ${this.getSelectedQty(item[0]) > 0 && 'active'}`}>{item[0].format('D')}</div>} {this.getSelectedQty(item[0]) > 0 && <div className="selected-qty">{this.getSelectedQty(item[0])}</div>}</td>
                                <td onClick={() => this.setProductSchedule(item[1])} className={item[1] != null && moment(item[1]).isBefore(moment(), 'day') ? 'past' : 'clickable'}>{item[1] == null ? '' : <div className={`day ${this.getSelectedQty(item[1]) > 0 && 'active'}`}>{item[1].format('D')}</div>} {this.getSelectedQty(item[1]) > 0 && <div className="selected-qty">{this.getSelectedQty(item[1])}</div>}</td>
                                <td onClick={() => this.setProductSchedule(item[2])} className={item[2] != null && moment(item[2]).isBefore(moment(), 'day') ? 'past' : 'clickable'}>{item[2] == null ? '' : <div className={`day ${this.getSelectedQty(item[2]) > 0 && 'active'}`}>{item[2].format('D')}</div>} {this.getSelectedQty(item[2]) > 0 && <div className="selected-qty">{this.getSelectedQty(item[2])}</div>}</td>
                                <td onClick={() => this.setProductSchedule(item[3])} className={item[3] != null && moment(item[3]).isBefore(moment(), 'day') ? 'past' : 'clickable'}>{item[3] == null ? '' : <div className={`day ${this.getSelectedQty(item[3]) > 0 && 'active'}`}>{item[3].format('D')}</div>} {this.getSelectedQty(item[3]) > 0 && <div className="selected-qty">{this.getSelectedQty(item[3])}</div>}</td>
                                <td onClick={() => this.setProductSchedule(item[4])} className={item[4] != null && moment(item[4]).isBefore(moment(), 'day') ? 'past' : 'clickable'}>{item[4] == null ? '' : <div className={`day ${this.getSelectedQty(item[4]) > 0 && 'active'}`}>{item[4].format('D')}</div>} {this.getSelectedQty(item[4]) > 0 && <div className="selected-qty">{this.getSelectedQty(item[4])}</div>}</td>
                                <td onClick={() => this.setProductSchedule(item[5])} className={item[5] != null && moment(item[5]).isBefore(moment(), 'day') ? 'past' : 'clickable'}>{item[5] == null ? '' : <div className={`day ${this.getSelectedQty(item[5]) > 0 && 'active'}`}>{item[5].format('D')}</div>} {this.getSelectedQty(item[5]) > 0 && <div className="selected-qty">{this.getSelectedQty(item[5])}</div>}</td>
                                <td onClick={() => this.setProductSchedule(item[6])} className={item[6] != null && moment(item[6]).isBefore(moment(), 'day') ? 'past' : 'clickable'}>{item[6] == null ? '' : <div className={`day ${this.getSelectedQty(item[6]) > 0 && 'active'}`}>{item[6].format('D')}</div>} {this.getSelectedQty(item[6]) > 0 && <div className="selected-qty">{this.getSelectedQty(item[6])}</div>}</td>
                              </tr>)}
                            </tbody>
                          </table>

                          <div className="actions d-flex">
                            <button onClick={this.setProductScheduleToday} type="button" className="flex-grow-1 send-all">Kirim Semua Hari Ini</button>
                            <div style={{minWidth: '12px'}}></div>
                            <button onClick={this.saveProductSchedule} type="button" className="flex-grow-1 done" data-dismiss="modal">Selesai</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="actions">
            <div data-toggle="modal" data-target="#note-modal">
              <FullWidthIconButton label="Catatan Untuk Penjual (Opsional)" sublabel={this.state.note != '' ? this.state.note : `Silahkan tulis pesan anda`} img={`${Config.BASE_URL}/assets/img/edit-icon.png`}></FullWidthIconButton>
            </div>
            <div className="modal fade" id="note-modal" data-backdrop="static">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body p-0">
                    <div className="card border-0">
                      <div className="card-header border-0">Catatan Untuk Penjual</div>
                      <div className="card-body border-0">
                        <textarea name="note" value={this.state.note} onChange={this.handleNoteChange} cols="30" rows="3" placeholder="Tulis catatan disini"></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button onClick={this.saveNote} type="button" className="btn btn-sm btn-secondary" data-dismiss="modal">Selesai</button>
                  </div>
                </div>
              </div>
            </div>
            <Link to="/select-coupon"><FullWidthIconButton label="Gunakan Voucher (Opsional)" sublabel={this.props.user.coupon != null ? this.props.user.coupon.name : `Pilih voucher untuk mendapatkan potongan`} img={`${Config.BASE_URL}/assets/img/ticket-icon.png`}></FullWidthIconButton></Link>
          </div>

          <div className="pay">
            <div className="container">
              {this.props.user.coupon != null && <div className="d-flex justify-content-between">
                <div className="sub-label">Potongan</div>
                <div className="sub-price">Rp {new Intl.NumberFormat().format(Config.calculateDiscount(this.props.user.coupon, this.getCartTotalPrice()))}</div>
              </div>}
              <div className="d-flex justify-content-between">
                <div className="label">Pembayaran:</div>
                <div className="price">Rp {new Intl.NumberFormat().format(this.getCartTotalPrice())}</div>
              </div>
              <div className="button">
                <button onClick={this.selectDeliveryMethod} type="button">Pilih Metode Pengiriman</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  }
}, (dispatch) => {
  return {
    getCart: () => dispatch(getCart()),
    setCart: (data) => dispatch(setCart(data)),
    getAddress: () => dispatch(getAddress()),
    setAddress: (data) => dispatch(setAddress(data)),
    getCoupon: () => dispatch(getCoupon()),
  }
})(withRouter(Cart));
