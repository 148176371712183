import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as Config from './../config';
import * as API from './../API';
import TitleBar from './../components/TitleBar';
import { getCart, setCart, getAddress, setAddress } from './../store/userSlice';

class SelectAddress extends React.Component {
  constructor(props) {
    super(props);
    if(this.props.user.value == null) this.props.history.replace('/sign-in');
    this.selectAddress = this.selectAddress.bind(this);

    this.state = {
      address: {
        list: [], val: null,
      }
    };
  }

  async componentDidMount() {
    if(this.props.user.value == null) return;
    this.state.address.list = await API.getAddressList(this.props.user.value.user_id);
    this.setState({ address: this.state.address });
    this.props.getAddress();
  }

  selectAddress(address) {
    this.props.setAddress(address);
    this.props.history.replace('/cart');
  }

  edit(address, e) {
    e.stopPropagation();
    this.props.history.push(`/select-location/edit/${address.id}`);
  }

  render() {
    return (
      <div className="flex-grow-1 d-flex flex-column overflow">
        <div className="select-address">
          <TitleBar title="Pilih Alamat Pengiriman" redirect="/cart" top="30%"></TitleBar>

          <div className="delivery-card">
            <div className="container">
              {this.state.address.list.map((item, index) => (
                <FadeIn key={index}>
                  <div onClick={() => this.selectAddress(item)} className="content clickable">
                    <div className="d-flex justify-content-between">
                      <div className="left">
                        <div className="type">Alamat {new Intl.NumberFormat().format(index + 1)}</div>
                        <div className="name">{this.props.user.value.name}</div>
                      </div>
                      <div className="right">
                        {this.props.user.address != null && item.id == this.props.user.address.id && <div className="selected-label">Dipilih</div>}
                      </div>
                    </div>
                    <div className="phone">{this.props.user.value.phone}</div>
                    <div className="address">{item.address}</div>
                    <hr/>
                    <div onClick={(e) => this.edit(item, e)} className="update-text">Ubah Alamat</div>
                  </div>
                </FadeIn>
              ))}
              <div className="button">
                <Link to="/select-location"><button type="button">+ Tambahkan Alamat Baru</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  }
}, (dispatch) => {
  return {
    getCart: () => dispatch(getCart()),
    setCart: (data) => dispatch(setCart(data)),
    getAddress: () => dispatch(getAddress()),
    setAddress: (data) => dispatch(setAddress(data)),
  }
})(withRouter(SelectAddress));
