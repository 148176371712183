import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as Config from './../config';
import { getLogin, logout } from './../store/userSlice';

class SideBar extends React.Component {
  constructor(props) {
    super(props);

    this.closeNav = this.closeNav.bind(this);
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft= "0";
  }

  render() {
    return (
      <div id="mySidenav" className="sidenav">
        <button type="button" className="closebtn" onClick={this.closeNav}>&times;</button>
        <Link to="/" onClick={this.closeNav}><i className="fa fa-home"></i> Home</Link>
        <Link to="/cart" onClick={this.closeNav}><i className="fa fa-shopping-cart"></i> Cart</Link>
        {this.props.user.value != null && <Link to="/profile" onClick={this.closeNav}><div className="link clickable"><div><i className="fa fa-user"></i> Profile</div></div></Link>}
        {this.props.user.value == null && <Link to="/sign-in" onClick={this.closeNav}><div className="link clickable"><div><i className="fa fa-user"></i> Sign In</div></div></Link>}
        <Link to="/contact" onClick={this.closeNav}><i className="fa fa-phone"></i> Contact</Link>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  }
}, (dispatch) => {
  return {
    getLogin: () => dispatch(getLogin()),
    logout: () => dispatch(logout()),
  }
})(withRouter(SideBar));
