import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { getLogin, getAlergic, getCart, getAddress } from './store/userSlice';
import * as Config from './config';

import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Profile from './pages/Profile';
import EditProfile from './pages/EditProfile';
import ProductList from './pages/ProductList';
import ProductDetail from './pages/ProductDetail';
import Cart from './pages/Cart';
import SelectAddress from './pages/SelectAddress';
import SelectLocation from './pages/SelectLocation';
import SelectCoupon from './pages/SelectCoupon';
import DeliveryMethod from './pages/DeliveryMethod';
import History from './pages/History';
import SideBar from './components/SideBar';
import TopBar from './components/TopBar';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.props.getLogin();
    this.props.getAlergic();
    this.props.getCart();
    this.props.getAddress();
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft= "0";
  }

  render() {
    return (
      <Router>
        <div className="app">
          <SideBar></SideBar>
          <TopBar></TopBar>
          <div id="main">
            <Switch>
              <Route path="/product-detail/:id">
                <ProductDetail></ProductDetail>
              </Route>
              <Route path="/product-list">
                <ProductList></ProductList>
              </Route>
              <Route path="/profile">
                <Profile></Profile>
              </Route>
              <Route path="/cart">
                <Cart></Cart>
              </Route>
              <Route path="/select-address">
                <SelectAddress></SelectAddress>
              </Route>
              <Route path="/select-location/edit/:id">
                <SelectLocation></SelectLocation>
              </Route>
              <Route path="/select-location">
                <SelectLocation></SelectLocation>
              </Route>
              <Route path="/select-coupon">
                <SelectCoupon></SelectCoupon>
              </Route>
              <Route path="/delivery-method">
                <DeliveryMethod></DeliveryMethod>
              </Route>
              <Route path="/edit-profile">
                <EditProfile></EditProfile>
              </Route>
              <Route path="/history">
                <History></History>
              </Route>
              <Route path="/sign-in">
                <SignIn></SignIn>
              </Route>
              <Route path="/sign-up">
                <SignUp></SignUp>
              </Route>
              <Route path="/">
                <Home></Home>
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  }
}, (dispatch) => {
  return {
    getLogin: () => dispatch(getLogin()),
    getAlergic: () => dispatch(getAlergic()),
    getCart: () => dispatch(getCart()),
    getAddress: () => dispatch(getAddress()),
  }
})(App);
