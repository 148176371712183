import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import * as Config from './../config';

class ProductCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div onClick={() => this.props.history.push(`/product-detail/${this.props.product.item_id}`)} className="product-card">
        <div className="item d-flex flex-column clickable">
          <div className="img">
            <img src={this.props.product.image_path} alt=""/>
          </div>
          <div className="info flex-grow-1">
            <div className="d-flex flex-column h-100 justify-content-center">
              <div className="top flex-grow-1">
                <div className="supplier-name">{this.props.product.supplier_name}</div>
                <div className="product-name">{this.props.product.name}</div>
              </div>
              <div className="bottom">
                <div className="price">Rp {new Intl.NumberFormat().format(this.props.product.price)}</div>
                {/* <div className="rating">
                  {this.props.product.star != null && parseFloat(this.props.product.star) < 1 && parseFloat(this.props.product.star) > 0 ? <i className="fa fa-star-half-o"></i> : parseFloat(this.props.product.star) > 0 ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i>}
                  {this.props.product.star != null && parseFloat(this.props.product.star) < 2 && parseFloat(this.props.product.star) > 1 ? <i className="fa fa-star-half-o"></i> : parseFloat(this.props.product.star) > 1 ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i>}
                  {this.props.product.star != null && parseFloat(this.props.product.star) < 3 && parseFloat(this.props.product.star) > 2 ? <i className="fa fa-star-half-o"></i> : parseFloat(this.props.product.star) > 2 ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i>}
                  {this.props.product.star != null && parseFloat(this.props.product.star) < 4 && parseFloat(this.props.product.star) > 3 ? <i className="fa fa-star-half-o"></i> : parseFloat(this.props.product.star) > 3 ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i>}
                  {this.props.product.star != null && parseFloat(this.props.product.star) < 5 && parseFloat(this.props.product.star) > 4 ? <i className="fa fa-star-half-o"></i> : parseFloat(this.props.product.star) > 4 ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i>}
                </div> */}
                <div className="action">
                  <button type="button">Beli</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ProductCard);
