import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import qs from 'qs';
import moment from 'moment';
import validator from 'validator';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import * as Config from './../config';

import ProductCard from './../components/ProductCard';
import TitleBar from './../components/TitleBar';

class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.setSortType = this.setSortType.bind(this);
    this.capitalize = this.capitalize.bind(this);

    this.state = {
      filter: {
        search: ``, minPrice: ``, maxPrice: ``,
      },
      sort: {
        by: { list: ['PRICE', 'RATING', 'DATE'], val: 'DATE' },
        type: { list: ['ASC', 'DESC'], val: 'DESC' },
      },
      product: { list: [], val: null }
    };
  }

  async componentDidMount() {
    let parameter = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    try {
      let formData = new FormData();
      if(parameter.category != null) formData.append('category', parameter.category);
      let response = await axios.post(`${Config.API_URL}/product/get-product-list`, formData);
      let data = response.data;
      if(data.status) {
        this.setState({ product: { list: data.data } });
        console.log(this.state.product);
      }
    } catch(error) {
      await Swal.fire('Failed', 'Unable to fetch product list', 'error');
    }
  }

  handleFilterChange(event) {
    let target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    if((['minPrice', 'maxPrice']).includes(name)) {
      if(validator.isInt(value)) this.state.filter[name] = parseInt(value);
      if(value == '') this.state.filter[name] = '';
    } else {
      this.state.filter[name] = value;
    }
    this.setState({ filter: this.state.filter });
  }

  handleSortChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.state.sort.by.val = value;
    this.setState({ sort: this.state.sort });
  }

  setSortType() {
    this.state.sort.type.val = this.state.sort.type.val == 'DESC' ? 'ASC' : 'DESC';
    this.setState({ sort: this.state.sort });
  }

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }

  getProductList() {
    let result = [];
    // filter
    for(let i=0;i<this.state.product.list.length;i++) {
      let product = this.state.product.list[i];
      let productName = product.name.trim().toLowerCase();
      let searchText = this.state.filter.search.trim().toLowerCase();
      let minPrice = this.state.filter.minPrice;
      let maxPrice = this.state.filter.maxPrice;
      if(
        (searchText == `` || productName.includes(searchText))
        &&
        (minPrice == `` || parseInt(minPrice) <= parseInt(product.price))
        &&
        (maxPrice == `` || parseInt(maxPrice) >= parseInt(product.price))
      ) result.push(product);
    }

    // sort
    switch(this.state.sort.by.val) {
      case 'DATE' :
        if(this.state.sort.type.val == 'ASC') {
          result.sort(function(a, b) {
            return moment(a.created_at).isAfter(moment(b.created_at)) ? 1 : (moment(a.created_at).isBefore(moment(b.created_at)) ? -1 : 0);
          });
        } else {
          result.sort(function(a, b) {
            return moment(b.created_at).isAfter(moment(a.created_at)) ? 1 : (moment(b.created_at).isBefore(moment(a.created_at)) ? -1 : 0);
          });
        }
      break;
      case 'PRICE' :
        if(this.state.sort.type.val == 'ASC') {
          result.sort(function(a, b) {
            return parseInt(a.price) > parseInt(b.price) ? 1 : parseInt(a.price) < parseInt(a.price) ? -1 : 0;
          });
        } else {
          result.sort(function(a, b) {
            return parseInt(b.price) > parseInt(a.price) ? 1 : parseInt(b.price) < parseInt(a.price) ? -1 : 0;
          });
        }
      break;
      case 'RATING' :
        if(this.state.sort.type.val == 'ASC') {
          result.sort(function(a, b) {
            return parseFloat(a.star ?? '0') > parseFloat(b.star ?? '0') ? 1 : parseFloat(a.star ?? '0') < parseFloat(a.star ?? '0') ? -1 : 0;
          });
        } else {
          result.sort(function(a, b) {
            return parseFloat(b.star ?? '0') > parseFloat(a.star ?? '0') ? 1 : parseFloat(b.star ?? '0') < parseFloat(a.star ?? '0') ? -1 : 0;
          });
        }
      break;
    }
    return result;
  }

  render() {
    return (
      <>
      <div className="flex-grow-1 d-flex flex-column overflow">
        <div className="product-list-bar"><TitleBar title="Livera Product" redirect="/" top="23%"></TitleBar></div>
        <div className="product-list">
          <div className="container">
            <div className="control-option d-flex">
              <div className="search-icon align-self-center"><i className="fa fa-search"></i></div>
              <div className="search flex-grow-1"><input name="search" type="text" value={this.state.filter.search} onChange={this.handleFilterChange} className="form-control" placeholder="Type here to search..."/></div>
              <div className="sort clickable" data-toggle="modal" data-target="#filter-modal">
                <div className="d-flex flex-column h-100 justify-content-center">
                  <div className="d-flex"><i className="fa fa-sliders"></i></div>
                </div>
              </div>
              <div className="modal fade" id="filter-modal">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body p-0">
                      <div className="card border-0">
                        <div className="card-header border-0">Sort by</div>
                        <div className="card-body border-0">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <select value={this.state.sort.by.val} onChange={this.handleSortChange} className="form-control">
                                {this.state.sort.by.list.map((item, index) => <option key={index} value={item}>{this.capitalize(item)}</option>)}
                              </select>
                            </div>
                            <div style={{minWidth: '4px'}}></div>
                            <div>
                              <button onClick={this.setSortType} className="filter-btn"><i className={`fa fa-angle-double-${this.state.sort.type.val == 'DESC' ? 'down' : 'up'}`}></i></button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card border-0">
                        <div className="card-header border-0">Filter by</div>
                        <div className="card-body border-0">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <input name="minPrice" value={this.state.filter.minPrice} type="text" onChange={this.handleFilterChange} className="form-control" placeholder="Min. Price"/>
                            </div>
                            <div style={{minWidth: '10px'}}></div>
                            <div className="flex-grow-1">
                              <input name="maxPrice" value={this.state.filter.maxPrice} type="text" onChange={this.handleFilterChange} className="form-control" placeholder="Max. Price"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-sm btn-secondary" data-dismiss="modal">close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="first-flex d-flex flex-wrap">
              {this.getProductList().length > 0 && this.getProductList().map((product, index) => <FadeIn key={index}><ProductCard product={product}></ProductCard></FadeIn>)}
              {this.getProductList().length == 0 && <div><small className="text-secondary"><em>Product tidak ditemukan</em></small></div>}
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default withRouter(ProductList);
