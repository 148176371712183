import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as Config from './../config';
import { getLogin, logout } from './../store/userSlice';
import FullWidthIconButton from './../components/FullWidthIconButton';
import BottomBar from './../components/BottomBar';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    if(this.props.user.value == null) this.props.history.replace('/sign-in');
    this.getIMT = this.getIMT.bind(this);
    this.getIMTStatus = this.getIMTStatus.bind(this);
    this.getAlergic = this.getAlergic.bind(this);

    this.state = {
      imtIcon: {
        less: <div className="imt-icon">
          <div className="d-flex justify-content-center">
            <div className="text align-self-center">Kurang</div>
            <div style={{minWidth: '8px'}}></div>
            <div className="icon"><img src={`${Config.BASE_URL}/assets/img/sad-icon.png`} alt=""/></div>
          </div>
        </div>,
        normal: <div className="imt-icon">
          <div className="d-flex justify-content-center">
            <div className="text align-self-center">Normal</div>
            <div style={{minWidth: '8px'}}></div>
            <div className="icon"><img src={`${Config.BASE_URL}/assets/img/smile-icon.png`} alt=""/></div>
          </div>
        </div>,
        more: <div className="imt-icon">
          <div className="d-flex justify-content-center">
            <div className="text align-self-center">Berlebih</div>
            <div style={{minWidth: '8px'}}></div>
            <div className="icon"><img src={`${Config.BASE_URL}/assets/img/sad-icon.png`} alt=""/></div>
          </div>
        </div>,
        obese: <div className="imt-icon">
          <div className="d-flex justify-content-center">
            <div className="text align-self-center">Obese</div>
            <div style={{minWidth: '8px'}}></div>
            <div className="icon"><img src={`${Config.BASE_URL}/assets/img/sad-icon.png`} alt=""/></div>
          </div>
        </div>,
      }
    };
  }

  getIMT() {
    if(this.props.user.value == null) return 0;
    if(this.props.user.value.weight == null || this.props.user.value.height == null) return 0;
    // rumus IMT : Weight / (Height * Height) in meter
    return this.props.user.value.weight / ((this.props.user.value.height / 100) * (this.props.user.value.height / 100));
  }

  getIMTStatus() {
    if(this.getIMT() < 18.5) return 'LESS';
    if(this.getIMT() <= 22.9) return 'NORMAL';
    if(this.getIMT() <= 29.9) return 'MORE';
    return 'OBESE';
  }

  getAlergic() {
    let result = '';
    if(this.props.user.alergic == null) return '-';
    for(let i=0;i<this.props.user.alergic.length;i++) {
      let alergic = this.props.user.alergic[i].alergic;
      if(result != '') result += ', ';
      result += alergic;
    }
    return result;
  }

  async componentDidMount() {

  }

  render() {
    return (
      <div className="d-flex flex-column h-100 flex-grow-1">
        <div className="flex-grow-1 overflow">
          <div className="profile-detail">
            <h3>Profil Saya</h3>
            {this.props.user.value == null && <Redirect to="/sign-in"></Redirect>}
            {this.props.user.value != null &&
            <div className="user-info">
              <div className="container d-flex">
                {this.props.user.value.profile_img != null && <FadeIn><div className="img align-self-start" style={{backgroundImage: `url(${Config.API_URL}/${this.props.user.value.profile_img})`}}></div></FadeIn>}
                {this.props.user.value.profile_img == null && <div className="img align-self-start"><img src={`${Config.BASE_URL}/assets/img/anonymous.png`} alt=""/></div>}
                <div style={{minWidth: '20px'}}></div>
                <div className="info align-self-center flex-grow-1">
                  <div className="d-flex flex-column">
                    <div className="name">{this.props.user.value.name}</div>
                    <div style={{minHeight: '8px'}}></div>
                    {(this.props.user.value.gender != null || this.props.user.value.dob != null) && <div className="gender-age">
                      <div className="d-flex">
                        <div className="gender">
                          {this.props.user.value.gender != null && this.props.user.value.gender != '' && <div className="d-flex">{this.props.user.value.gender == 'Male' ? <img src={`${Config.BASE_URL}/assets/img/male-icon.png`} className="male-icon" alt=""/> : <img src={`${Config.BASE_URL}/assets/img/female-icon.png`} className="female-icon" alt=""/>} <span style={{minWidth: '4px'}}></span> <span className="align-self-center">{this.props.user.value.gender}</span></div>}
                        </div>
                        <div style={{minWidth: '8px'}}></div>
                        <div className="ellipse align-self-center"></div>
                        <div style={{minWidth: '8px'}}></div>
                        <div className="age">{this.props.user.value.dob != null && this.props.user.value.dob != '' && moment().diff(moment(this.props.user.value.dob, 'YYYY-MM-DD', true), 'years')} thn</div>
                      </div>
                    </div>}
                    <Link to="/edit-profile"><button>Atur Profil</button></Link>
                  </div>
                </div>
              </div>
            </div>}
            {this.props.user.value != null &&
            <div className="goal">
              <div className="goal-wrapper">
                <div className="container">
                  <div className="label">Goal kamu</div>
                  <div className="content">{this.props.user.value.goal != null ? this.props.user.value.goal : 'Kamu belum memiliki goal'}</div>
                </div>
              </div>
            </div>}
            {this.props.user.value != null &&
            <div className="health">
              <div className="section">
                <div className="container">
                  <div className="imt d-flex justify-content-between">
                    <div className="left">
                      <div className="d-flex">
                        <div className="label align-self-center">Index Massa Tubuh</div>
                        <div style={{minWidth: '8px'}}></div>
                        <div className="help clickable" data-toggle="modal" data-target="#imt-modal"><img src={`${Config.BASE_URL}/assets/img/help-icon.png`} alt=""/></div>
                        <div className="modal fade" id="imt-modal">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-body p-0">
                                <h2>Indeks Massa Tubuh</h2>
                                <p>Angka yang menjadi penilaian standar untuk menentukan apakah berat badan Anda tergolong normal, kurang, berlebih, atau obesitas.</p>
                              </div>
                              <div className="separator"></div>
                              <div className="item">
                                <div className="d-flex justify-content-between">
                                  <div className="label align-self-center">Dibawah 18,5</div>
                                  {this.state.imtIcon.less}
                                </div>
                              </div>
                              <div className="separator"></div>
                              <div className="item">
                                <div className="d-flex justify-content-between">
                                  <div className="label align-self-center">18,5 - 22,9</div>
                                  {this.state.imtIcon.normal}
                                </div>
                              </div>
                              <div className="separator"></div>
                              <div className="item">
                                <div className="d-flex justify-content-between">
                                  <div className="label align-self-center">23 - 29,9</div>
                                  {this.state.imtIcon.more}
                                </div>
                              </div>
                              <div className="separator"></div>
                              <div className="item">
                                <div className="d-flex justify-content-between">
                                  <div className="label align-self-center">Lebih dari 30</div>
                                  {this.state.imtIcon.obese}
                                </div>
                              </div>
                              <div className="separator"></div>
                              <div className="close-wrap"><button className="close-button" data-dismiss="modal">TUTUP</button></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{minHeight: '8px'}}></div>
                      <div className="d-flex">
                        <div><img src={`${Config.BASE_URL}/assets/img/body-icon.png`} alt=""/></div>
                        <div style={{minWidth: '8px'}}></div>
                        <div className="label align-self-center">{this.getIMT() > 0 ? new Intl.NumberFormat('en-NZ', { maximumFractionDigits: 2 }).format(this.getIMT()) : `Masukkan berat dan tinggi kamu dulu ya`}</div>
                      </div>
                    </div>
                    {this.getIMT() > 0 && <div className="right">
                      {this.getIMTStatus() == 'LESS' && this.state.imtIcon.less}
                      {this.getIMTStatus() == 'NORMAL' && this.state.imtIcon.normal}
                      {this.getIMTStatus() == 'MORE' && this.state.imtIcon.more}
                      {this.getIMTStatus() == 'OBESE' && this.state.imtIcon.obese}
                    </div>}
                  </div>
                </div>
              </div>
              <div className="section-2">
                <div className="container">
                  <div className="d-flex">
                    <div className="left flex-grow-1">
                      <div className="label">Berat Badan</div>
                      <div style={{minHeight: '8px'}}></div>
                      <div className="d-flex">
                        <div className="img"><img src={`${Config.BASE_URL}/assets/img/weight-icon.png`} alt=""/></div>
                        <div style={{minWidth: '8px'}}></div>
                        <div className="content align-self-center">{this.props.user.value.weight == null ? '-' : new Intl.NumberFormat().format(this.props.user.value.weight) + ' kg'}</div>
                      </div>
                    </div>
                    <div className="separator"></div>
                    <div className="right flex-grow-1" style={{paddingLeft: '15px'}}>
                      <div className="label">Tinggi Badan</div>
                      <div style={{minHeight: '8px'}}></div>
                      <div className="d-flex">
                        <div className="img"><img src={`${Config.BASE_URL}/assets/img/height-icon.png`} alt=""/></div>
                        <div style={{minWidth: '8px'}}></div>
                        <div className="content align-self-center">{this.props.user.value.height == null ? '-' : new Intl.NumberFormat().format(this.props.user.value.height) + ' cm'}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-2">
                <div className="container">
                  <div className="d-flex">
                    <div className="left flex-grow-1">
                      <div className="label">Alergi</div>
                      <div style={{minHeight: '8px'}}></div>
                      <div className="d-flex">
                        <div className="img"><img src={`${Config.BASE_URL}/assets/img/alergic-icon.png`} alt=""/></div>
                        <div style={{minWidth: '8px'}}></div>
                        <div className="content align-self-center">{this.getAlergic() == '' ? '-' : this.getAlergic()}</div>
                      </div>
                    </div>
                    <div className="separator"></div>
                    <div className="right flex-grow-1" style={{paddingLeft: '15px'}}>
                      <div className="label">Penyakit</div>
                      <div style={{minHeight: '8px'}}></div>
                      <div className="d-flex">
                        <div className="img"><img src={`${Config.BASE_URL}/assets/img/virus-icon.png`} alt=""/></div>
                        <div style={{minWidth: '8px'}}></div>
                        <div className="content align-self-center">{this.props.user.value.is_gerd == null || this.props.user.value.is_gerd == '0' ? '-' : 'GERD'}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
            <div className="actions">
              <FullWidthIconButton label="Voucher Saya" img={`${Config.BASE_URL}/assets/img/ticket-icon.png`}></FullWidthIconButton>
              <FullWidthIconButton label="Pengaturan" img={`${Config.BASE_URL}/assets/img/settings-icon.png`}></FullWidthIconButton>
              <FullWidthIconButton label="Bagikan" img={`${Config.BASE_URL}/assets/img/share-icon.png`}></FullWidthIconButton>
              <div onClick={this.props.logout}><FullWidthIconButton label="logout" color="red" img={`${Config.BASE_URL}/assets/img/logout-icon.png`}></FullWidthIconButton></div>
            </div>
          </div>
        </div>

        <BottomBar></BottomBar>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  }
}, (dispatch) => {
  return {
    getLogin: () => dispatch(getLogin()),
    logout: () => dispatch(logout()),
  }
})(withRouter(Profile));
