export const API_URL = `https://api.livera.id`;
// export const BASE_URL = `http://localhost:3000`;
export const BASE_URL = `https://app.livera.id`;
export const MAP_API = `AIzaSyAD_65dXf4KkWtLiPjzxmnC-D3iIVQ5Wro`;
// AIzaSyBq4AD_A3eIsflTTxMDDSYE5YzDqiJ3Z7k

export const calculateDiscount = (coupon, amount) => {
  if(coupon == null) return 0;
  if(coupon.is_active == 0) return 0;
  if(coupon.min_value != null && amount < coupon.min_value) return 0;
  if(coupon.type == 'VALUE') return coupon.value;
  if(coupon.type == 'PERCENTAGE') {
    let discount = parseInt((amount * coupon.percentage / 100));
    if(coupon.max_value != null) {
      if(discount > coupon.max_value) return coupon.max_value;
      else return discount;
    }
    return discount;
  }
}
