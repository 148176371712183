import React from 'react';
import axios from 'axios';
import moment from 'moment';
import validator from 'validator';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { reactLocalStorage } from 'reactjs-localstorage';
import * as Config from './../config';
import { getLogin, setLogin, setAlergic, logout } from './../store/userSlice';
import FullWidthIconButton from './../components/FullWidthIconButton';
import TitleBar from './../components/TitleBar';

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    if(this.props.user.value == null) this.props.history.replace('/sign-in');
    this.changeImage = this.changeImage.bind(this);
    this.setBirthdate = this.setBirthdate.bind(this);
    this.setAlergic = this.setAlergic.bind(this);
    this.setGerd = this.setGerd.bind(this);
    this.submit = this.submit.bind(this);
    this.refreshForm = this.refreshForm.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleDateChangeRaw = this.handleDateChangeRaw.bind(this);

    this.state = {
      isLoading: false,
      form: {
        profileImg: { preview: null, file: null },
        fullName: ``,
        gender: ``,
        birthdate: new Date(),
        goalList: ['Menaikkan Berat Badan', 'Menurunkan Berat Badan'],
        goalVal: '',
        weight: '',
        height: '',
        alergic: {
          list: ['Udang', 'Kacang', 'Susu'],
          val: []
        },
        isGerd: false,
      },
    };
  }

  async componentDidMount() {
    this.refreshForm();
    console.log(this.props.user.alergic);
  }

  refreshForm() {
    let form = this.state.form;
    form.fullName = this.props.user.value.name ?? '';
    form.gender = this.props.user.value.gender == null ? '' : this.props.user.value.gender.toLowerCase() == 'male' ? 'Male' : this.props.user.value.gender.toLowerCase() == 'female' ? 'Female' : ``;
    form.birthdate = this.props.user.value.dob == null ? new Date() : moment(this.props.user.value.dob).toDate();
    form.weight = this.props.user.value.weight ?? '';
    form.height = this.props.user.value.height ?? '';
    form.goalVal = this.props.user.value.goal ?? '';
    form.isGerd = this.props.user.value.is_gerd == '1' ? true : false;
    for(let i=0;i<this.props.user.alergic.length;i++) form.alergic.val.push(this.props.user.alergic[i].alergic);
    this.setState({ form: this.state.form });
  }

  setAlergic(item) {
    if(this.state.form.alergic.val.indexOf(item) === -1) this.state.form.alergic.val.push(item);
    else this.state.form.alergic.val.splice(this.state.form.alergic.val.indexOf(item), 1);
    this.setState({ form: this.state.form });
  }

  setGender(gender) {
    this.state.form.gender = gender;
    this.setState({ form: this.state.form });
  }

  setGerd(gerd) {
    this.state.form.isGerd = gerd;
    this.setState({ form: this.state.form });
  }

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  setBirthdate(date) {
    this.state.form.birthdate = moment(date).toDate();
    this.setState({ form: this.state.form });
  }

  changeImage() {
    let file = document.getElementById('change-profile-img').files[0];
    if(file == null) {
      this.state.form.profileImg.preview = null;
      this.state.form.profileImg.file = null;
      this.setState({ form: this.state.form });
      return;
    }
    this.state.form.profileImg.file = file;
    let reader = new FileReader();
    reader.addEventListener('load', () => {
      this.state.form.profileImg.preview = reader.result;
      this.setState({ form: this.state.form });
    });
    reader.readAsDataURL(file);
  }

  handleFormChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    if((['weight', 'height']).includes(name)) {
      if(validator.isInt(value)) this.state.form[name] = parseInt(value);
      if(value == '') this.state.form[name] = '';
    } else {
      this.state.form[name] = value;
    }
    this.setState({ form: this.state.form });
  }

  async submit() {
    if(this.state.isLoading) return;
    let form = this.state.form;
    if(validator.isEmpty(form.fullName)) return Swal.fire('Nama lengkap wajib diisi');
    if(form.gender == '') return Swal.fire('Gender wajib diisi');
    if(form.goalVal == '') return Swal.fire('Goal wajib dipilih');
    if(form.weight == '') return Swal.fire('Berat Badan wajib disi');
    if(form.height == '') return Swal.fire('Tinggi Badan wajib disi');
    this.state.isLoading = true;
    let formData = new FormData();
    formData.append('user_id', this.props.user.value.user_id);
    if(this.state.form.profileImg.file != null) formData.append('profileImage', this.state.form.profileImg.file);
    let firstName = this.state.form.fullName.split(' ')[0];
    let lastName = this.state.form.fullName.split(' ').slice(1).join(' ');
    formData.append('firstname', firstName);
    formData.append('lastname', lastName);
    formData.append('gender', this.state.form.gender);
    formData.append('height', this.state.form.height);
    formData.append('weight', this.state.form.weight);
    formData.append('dob', moment(this.state.form.birthdate).format('YYYY-MM-DD'));
    formData.append('goal', this.state.form.goalVal);
    formData.append('isGerd', this.state.form.isGerd ? '1' : '0');
    formData.append('alergic', JSON.stringify(this.state.form.alergic.val));
    try {
      let response = await axios.post(`${Config.API_URL}/update-user-profile`, formData);
      let data = response.data;
      if(data.status) {
        this.props.setLogin(data.data);
        this.props.setAlergic(data.data.alergic);
        this.refreshForm();
        await Swal.fire('Your profile updated', '', 'success');
      }
    } catch(error) {
      await Swal.fire('Failed', 'Unable to update profile', 'error');
    }
    this.state.isLoading = false;
    this.props.history.push(`/profile`);
  }

  render() {
    return (
      <>
      <div className="flex-grow-1 d-flex flex-column overflow">
        <div className="edit-profile">
          <TitleBar title="Atur Profile" redirect="/profile"></TitleBar>
          {this.props.user.value != null &&
          <div className="profile-img">
            <div className="container d-flex justify-content-center">
              {this.state.form.profileImg.preview == null && this.props.user.value.profile_img == null && <FadeIn><div className="img" style={{backgroundImage: `url(${Config.BASE_URL}/assets/img/anonymous.png)`}}></div></FadeIn>}
              {this.state.form.profileImg.preview == null && this.props.user.value.profile_img != null && <FadeIn><div className="img" style={{backgroundImage: `url(${Config.API_URL}/${this.props.user.value.profile_img})`}}></div></FadeIn>}
              {this.state.form.profileImg.preview != null && <FadeIn><div className="img" style={{backgroundImage: `url(${this.state.form.profileImg.preview})`}}></div></FadeIn>}
            </div>
            <div className="change-img"><div onClick={() => document.getElementById('change-profile-img').click()} className="clickable button">Ubah Foto</div></div>
            <input onChange={this.changeImage} id="change-profile-img" type="file" accept=".jpg,.png"/>
          </div>}
          <div className="form">
            <div className="container">
              <div className="form-group">
                <label>Nama Lengkap</label>
                <div className="input-field">
                  <input name="fullName" value={this.state.form.fullName} onChange={this.handleFormChange} type="text"/>
                </div>
              </div>
              <div className="form-group">
                <label>Gender</label>
                <div className="d-flex">
                  <div className="input-field flex-grow-1">
                    <div onClick={() => this.setGender('Male')} className={`select-button ${this.state.form.gender == 'Male' ? 'active' : ''} clickable`}>
                      <div className="d-flex">
                        <div className="icon align-self-center"><img src={`${Config.BASE_URL}/assets/img/male-icon.png`} alt=""/></div>
                        <div style={{minWidth: '8px'}}></div>
                        <div className="text align-self-center pt-1">Laki - laki</div>
                      </div>
                    </div>
                  </div>
                  <div style={{minWidth: '12px'}}></div>
                  <div className="input-field flex-grow-1">
                    <div onClick={() => this.setGender('Female')} className={`select-button ${this.state.form.gender == 'Female' ? 'active' : ''} clickable`}>
                      <div className="d-flex">
                        <div className="icon align-self-center"><img src={`${Config.BASE_URL}/assets/img/female-icon.png`} alt=""/></div>
                        <div style={{minWidth: '8px'}}></div>
                        <div className="text align-self-center pt-1">Perempuan</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Birthdate</label>
                <div className="input-field">
                  <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.form.birthdate} onChangeRaw={this.handleDateChangeRaw} onChange={date => this.setBirthdate(date)} showYearDropdown={true} dropdownMode={'select'} maxDate={moment().toDate()} />
                </div>
              </div>
              <div className="form-group">
                <label>Goal</label>
                <div className="input-field">
                  <select name="goalVal" value={this.state.form.goalVal} onChange={this.handleFormChange}>
                    <option value="">Pilih goal kamu</option>
                    {this.state.form.goalList.map((item, index) => <option key={index} value={item}>{item}</option>)}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label>Berat Badan</label>
                <div className="input-field">
                  <div className="d-flex h-100">
                    <div className="flex-grow-1"><input name="weight" value={this.state.form.weight} onChange={this.handleFormChange} type="text" placeholder="Masukkan berat badan kamu"/></div>
                    <div className="unit">
                      <div className="d-flex flex-column h-100 justify-content-center">
                        <div>kg</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Tinggi Badan</label>
                <div className="input-field">
                  <div className="d-flex h-100">
                    <div className="flex-grow-1"><input name="height" value={this.state.form.height} onChange={this.handleFormChange} type="text" placeholder="Masukkan tinggi badan kamu"/></div>
                    <div className="unit">
                      <div className="d-flex flex-column h-100 justify-content-center">
                        <div>cm</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Alergi</label>
                <div className="d-flex">
                  <div className="input-field flex-grow-1">
                    <div onClick={() => this.setAlergic('Udang')} className={`select-button ${this.state.form.alergic.val.includes('Udang') ? 'active' : ''} clickable`}>
                      <div className="d-flex">
                        <div className="icon align-self-center"><img src={`${Config.BASE_URL}/assets/img/shrimp-icon.png`} alt=""/></div>
                        <div style={{minWidth: '8px'}}></div>
                        <div className="text align-self-center pt-1">Udang</div>
                      </div>
                    </div>
                  </div>
                  <div style={{minWidth: '12px'}}></div>
                  <div className="input-field flex-grow-1">
                    <div onClick={() => this.setAlergic('Kacang')} className={`select-button ${this.state.form.alergic.val.includes('Kacang') ? 'active' : ''} clickable`}>
                      <div className="d-flex">
                        <div className="icon align-self-center"><img src={`${Config.BASE_URL}/assets/img/peanut-icon.png`} alt=""/></div>
                        <div style={{minWidth: '8px'}}></div>
                        <div className="text align-self-center pt-1">Kacang</div>
                      </div>
                    </div>
                  </div>
                  <div style={{minWidth: '12px'}}></div>
                  <div className="input-field flex-grow-1">
                    <div onClick={() => this.setAlergic('Susu')} className={`select-button ${this.state.form.alergic.val.includes('Susu') ? 'active' : ''} clickable`}>
                      <div className="d-flex">
                        <div className="icon align-self-center"><img src={`${Config.BASE_URL}/assets/img/milk-icon.png`} alt=""/></div>
                        <div style={{minWidth: '8px'}}></div>
                        <div className="text align-self-center pt-1">Susu</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Apakah kamu menderita GERD</label>
                <div className="d-flex">
                  <div className="input-field flex-grow-1">
                    <div onClick={() => this.setGerd(true)} className={`select-button ${this.state.form.isGerd ? 'active' : ''} clickable`}>
                      <div className="d-flex">
                        <div className="text align-self-center pt-1">Ya</div>
                      </div>
                    </div>
                  </div>
                  <div style={{minWidth: '12px'}}></div>
                  <div className="input-field flex-grow-1">
                    <div onClick={() => this.setGerd(false)} className={`select-button ${!this.state.form.isGerd ? 'active' : ''} clickable`}>
                      <div className="d-flex">
                        <div className="text align-self-center pt-1">Tidak</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button onClick={this.submit} type="button" className="button">Simpan</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  }
}, (dispatch) => {
  return {
    getLogin: () => dispatch(getLogin()),
    setLogin: (user) => dispatch(setLogin(user)),
    setAlergic: (data) => dispatch(setAlergic(data)),
    logout: () => dispatch(logout()),
  }
})(withRouter(EditProfile));
