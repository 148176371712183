import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import validator from 'validator';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import { connect } from 'react-redux';
import * as Config from './../config';

import { getLogin, setLogin, logout } from './../store/userSlice';

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.register = this.register.bind(this);

    this.state = {
      isLoading: false,
      form: {
        fullName: ``,
        phone: ``,
        email: ``,
        password: ``,
        rePassword: ``,
      },
    };
  }

  handleFormChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.state.form[name] = value;
    this.setState({ form: this.state.form });
  }

  async register() {
    if(this.state.isLoading) return;
    try {
      if(validator.isEmpty(this.state.form.fullName)) return Swal.fire('Full name tidak boleh kosong');
      if(validator.isEmpty(this.state.form.phone)) return Swal.fire('Nomor handphone wajib diisi');
      if(!validator.isMobilePhone(this.state.form.phone, ['id-ID'])) return Swal.fire('Nomor handphone tidak valid');
      if(validator.isEmpty(this.state.form.email)) return Swal.fire('Email tidak boleh kosong');
      if(!validator.isEmail(this.state.form.email)) return Swal.fire('Email tidak valid');
      if(validator.isEmpty(this.state.form.password)) return Swal.fire('Password harus minimal 8 angka, terdiri dari huruf kecil, huruf besar dan angka');
      if(!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/.test(this.state.form.password)) return Swal.fire('Password tidak valid');
      if(this.state.form.password != this.state.form.rePassword) return Swal.fire('Konfirmasi password tidak sesuai');
      this.state.isLoading = true;
      let formData = new FormData();
      formData.append('name', this.state.form.fullName);
      formData.append('phone', this.state.form.phone);
      formData.append('email', this.state.form.email);
      formData.append('password', this.state.form.password);
      let response = await axios.post(`${Config.API_URL}/register`, formData);
      let data = response.data;
      if(data.status) {
        this.props.setLogin(data.data);
        await Swal.fire('Success', 'Welcome to Livera!', 'success');
      } else {
        await Swal.fire('Failed', data.msg, 'warning');
      }
    } catch(error) {
      console.log(error);
      await Swal.fire('Failed', 'Unable to connect to server', 'error');
    }
    this.state.isLoading = false;
  }

  render() {
    return (
      <>
      <div className="register">
        <div className="d-flex flex-column justify-content-center h-100">
          <div className="form row no-gutters">
            <div className="col-md-4"></div>
            <div className="form-inner col-md-4">
              {this.props.user.value != null && <Redirect to="/" />}
              <h3>Sign Up</h3>
              <hr/>
              <div className="form-group">
                <label>Full Name</label>
                <input name="fullName" type="text" onChange={this.handleFormChange} className="form-control"/>
              </div>
              <div className="form-group">
                <label>Phone</label>
                <input name="phone" type="phone" onChange={this.handleFormChange} className="form-control"/>
              </div>
              <div className="form-group">
                <label>Email</label>
                <input name="email" type="email" onChange={this.handleFormChange} className="form-control"/>
              </div>
              <div className="form-group">
                <label>Password</label>
                <input name="password" type="password" onChange={this.handleFormChange} className="form-control"/>
              </div>
              <div className="form-group">
                <label>Re-Password</label>
                <input name="rePassword" type="password" onChange={this.handleFormChange} className="form-control"/>
              </div>
              <div className="form-group">
                <button onClick={this.register} type="button">Sign Up</button>
              </div>
              <div className="form-group">
                <div className="alt">Already have an account ? <Link to="/sign-in"><strong className="clickable">Sign In</strong></Link></div>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  }
}, (dispatch) => {
  return {
    getLogin: () => dispatch(getLogin()),
    setLogin: (user) => dispatch(setLogin(user)),
    logout: () => dispatch(logout()),
  }
})(withRouter(SignUp))
