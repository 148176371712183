import React from 'react';

class FullWidthIconButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let item = this.props.review;
    return (
      <div className="item clickable">
        <div className="container d-flex h-100">
          <div className="img align-self-center"><img src={this.props.img} alt=""/></div>
          <div style={{minWidth: '16px'}}></div>
          <div className={`flex-grow-1 align-self-center`} style={{overflow: 'hidden'}}>
            <div className={`label ${this.props.color == 'red' ? 'label-red' : ''}`}>{this.props.label}</div>
            <div className="sublabel">{this.props.sublabel}</div>
          </div>
          <div style={{minWidth: '10px'}}></div>
          <div className="arrow align-self-center"><i className={`fa ${this.props.color == 'red' ? 'fa-red' : ''} fa-angle-right`}></i></div>
        </div>
      </div>
    );
  }
}

export default FullWidthIconButton;
