import React from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/id';
import Swal from 'sweetalert2';
import FadeIn from 'react-fade-in';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as Config from './../config';
import * as API from './../API';
import { reactLocalStorage } from 'reactjs-localstorage';
import { getCart, setCart, getAddress, setAddress, getCoupon } from './../store/userSlice';
import FullWidthIconButton from './../components/FullWidthIconButton';
import TitleBar from './../components/TitleBar';

class DeliveryMethod extends React.Component {
  constructor(props) {
    super(props);
    if(this.props.user.value == null) this.props.history.replace('/sign-in');
    this.getCartTotalPrice = this.getCartTotalPrice.bind(this);
    this.setMethodType = this.setMethodType.bind(this);
    this.setDeliveryMethod = this.setDeliveryMethod.bind(this);
    this.getDateList = this.getDateList.bind(this);
    this.getScheduleItem = this.getScheduleItem.bind(this);
    this.getCourier = this.getCourier.bind(this);
    this.getDeliveryMethod = this.getDeliveryMethod.bind(this);

    this.state = {
      cartSchedule: [],
      courier: { list: [], val: null },
      methodType: { list: ['ALL', 'EACH'], val: null },
    };
  }

  async componentDidMount() {
    if(this.props.user.value == null) return;
    let cart = await API.getCart(this.props.user.value.user_id);
    this.props.setCart(cart);
    let cartSchedule = reactLocalStorage.getObject('cartSchedule', null);
    for(let i=0;i<this.props.user.cart.length;i++) {
      let schedule = [];
      for(let s=0;s<cartSchedule.length;s++) {
        if(cartSchedule[s].id == this.props.user.cart[i].id) {
          schedule = cartSchedule[s].schedule;
        }
      }
      this.state.cartSchedule.push({
        id: this.props.user.cart[i].id,
        schedule: schedule,
        delivery: [],
      });
    }
    this.setState({cartSchedule: this.state.cartSchedule});

    let cartNote = reactLocalStorage.getObject('cartNote', null);
    if(cartNote != null) {
      this.setState({note: cartNote});
    }

    this.props.getAddress();
    if(this.props.user.address == null) {
      let addressList = await API.getAddressList(this.props.user.value.user_id);
      for(let i=0;i<addressList.length;i++) {
        if(addressList[i].is_primary == '1') {
          this.props.setAddress(addressList[i]); break;
        }
      }
    }

    this.props.getCoupon();

    // get first product supplier in cart
    let supplierID = this.props.user.cart[0].supplier_id;
    let longitude = Number(this.props.user.address.longitude);
    let latitude = Number(this.props.user.address.latitude);
    this.state.courier.list = await API.getCourierList(supplierID, longitude, latitude);
    this.setState({courier: this.state.courier});
    console.log(this.state.courier);
  }

  getCartTotalPrice() {
    let result = 0;
    for(let i=0;i<this.props.user.cart.length;i++) {
      let item = this.props.user.cart[i];
      result += Number(item.cart_qty) * Number(item.price);
    }
    return result;
  }

  setMethodType(type) {
    if(this.state.methodType.val == type) this.state.methodType.val = null;
    else this.state.methodType.val = type;
    this.setState({methodType: this.state.methodType});
  }

  setDeliveryMethod(method, date) {
    for(let i=0;i<this.state.cartSchedule.length;i++) {
      for(let s=0;s<this.state.cartSchedule[i].schedule.length;s++) {
        if(date == null) this.state.cartSchedule[i].delivery[s] = method;
        if(date != null && moment(this.state.cartSchedule[i].schedule[s]).isSame(date, 'day')) {
          this.state.cartSchedule[i].delivery[s] = method;
        }
      }
    }
    this.setState({cartSchedule: this.state.cartSchedule});
  }

  getDateList() {
    let dateList = [];
    for(let i=0;i<this.state.cartSchedule.length;i++) {
      for(let s=0;s<this.state.cartSchedule[i].schedule.length;s++) {
        let newItem = true;
        let date = moment(this.state.cartSchedule[i].schedule[s], true).startOf('day');
        for(let r=0;r<dateList.length;r++) {
          if(moment(dateList[r].date).isSame(date)) {
            dateList[r].item.push(this.state.cartSchedule[i]);
            newItem = false;
          }
        }
        if(newItem) {
          dateList.push({
            date: date, item: [this.state.cartSchedule[i]],
          });
        }
      }
    }
    return dateList;
  }

  getCourier(date) {
    let result;
    if(date != null) {
      for(let i=0;i<this.state.cartSchedule.length;i++) {
        let schedule = this.state.cartSchedule[i];
        for(let s=0;s<schedule.schedule.length;s++) {
          let scheduleDate = schedule.schedule[s];
          if(moment(date).isSame(scheduleDate, 'day')) {
            result = schedule.delivery[s];
            if(result != null) return result;
          }
        }
      }
    } else {
      for(let i=0;i<this.state.cartSchedule.length;i++) {
        let schedule = this.state.cartSchedule[i];
        for(let s=0;s<schedule.schedule.length;s++) {
          let scheduleDate = schedule.schedule[s];
          if(schedule.delivery[s] == null) return null;
          if(result == null) result = schedule.delivery[s];
          else {
            if(result.id != schedule.delivery[s].id) return null;
          }
        }
      }
    }
    return result;
  }

  getScheduleItem(schedule) {
    let result = '';
    let item = { };
    for(let i=0;i<schedule.item.length;i++) {
      if(item[schedule.item[i].id] == null) item[schedule.item[i].id] = 1;
      else item[schedule.item[i].id]++;
    }
    for(let cartID in item) {
      let cartItem;
      for(let i=0;i<this.props.user.cart.length;i++) {
        if(this.props.user.cart[i].id == cartID) {
          if(result != '') result += ' & ';
          result += `${item[cartID]} ${this.props.user.cart[i].name}`;
          break;
        }
      }
    }
    return result;
  }

  getDeliveryMethod() {
    let result = { qty: 0, price: 0 };
    let dateList = this.getDateList();
    for(let i=0;i<dateList.length;i++) {
      let courier = this.getCourier(dateList[i].date);
      if(courier != null) {
        result.qty++;
        result.price += courier.total_price;
      }
    }
    return result;
  }

  render() {
    return (
      <div className="flex-grow-1 d-flex flex-column overflow">
        <div className="delivery-method flex-grow-1">
          <TitleBar title="Metode Pengiriman" redirect="/cart" top="35%"></TitleBar>

          <div className="delivery-list">
            <div className="item">
              <div className="container">
                <div onClick={() => this.setMethodType('ALL')} className="d-flex justify-content-between clickable">
                  <div>
                    <h5>Pilih metode pengiriman untuk semua tanggal</h5>
                    <p>Mengatur metode pengiriman yang sama</p>
                  </div>
                  <div style={{minWidth: '8px'}}></div>
                  <div className="align-self-center">
                    <img src={`${Config.BASE_URL}/assets/img/toggle-${this.state.methodType.val == 'ALL' ? 'up' : 'down'}-icon.png`} alt=""/>
                  </div>
                </div>
                {this.state.methodType.val == 'ALL' && <div className="courier-list">
                  {this.state.courier.list.map((item, index) => (
                    <FadeIn key={index}>
                      <div onClick={() => this.setDeliveryMethod(item)} className="courier-item d-flex clickable">
                        <div className="radio align-self-center"><img src={`${Config.BASE_URL}/assets/img/radio${this.getCourier() != null && this.getCourier().id == item.id ? '-selected' : ''}-icon.png`} alt=""/></div>
                        <div style={{minWidth: '16px'}}></div>
                        <div className="img"><img src={item.image_path} onError={(e)=>{e.target.onerror = null; e.target.src = `${Config.BASE_URL}/assets/img/alergic-icon.png`}} alt=""/></div>
                        <div style={{minWidth: '16px'}}></div>
                        <div className="info align-self-center">
                          <div className="courier-name">{item.name}</div>
                          <div className="courier-desc">Rp {new Intl.NumberFormat().format(item.total_price)}</div>
                        </div>
                      </div>
                    </FadeIn>
                  ))}
                </div>}
              </div>
            </div>
            <div className="item clickable">
              <div className="container">
                <div onClick={() => this.setMethodType('EACH')} className="d-flex justify-content-between">
                  <div>
                    <h5>Atur Metode Pengiriman untuk Setiap Tanggal</h5>
                    <p>Mengatur metode pengiriman yang berbeda - beda</p>
                  </div>
                  <div style={{minWidth: '8px'}}></div>
                  <div className="align-self-center"><img src={`${Config.BASE_URL}/assets/img/toggle-${this.state.methodType.val == 'EACH' ? 'up' : 'down'}-icon.png`} alt=""/></div>
                </div>
                {this.state.methodType.val == 'EACH' && <div className="courier-list">
                  {this.getDateList().map((schedule, index) => (
                    <div key={index} className="date-list">
                      <div className="delivery-date">{moment(schedule.date).format('dddd, DD MMMM YYYY')}</div>
                      <div className="delivery-item">{this.getScheduleItem(schedule)}</div>
                      {this.state.courier.list.map((item, index) => (
                        <FadeIn key={index}>
                          <div onClick={() => this.setDeliveryMethod(item, schedule.date)} className="courier-item d-flex clickable">
                            <div className="radio align-self-center"><img src={`${Config.BASE_URL}/assets/img/radio${this.getCourier(schedule.date) != null && this.getCourier(schedule.date).id == item.id ? '-selected' : ''}-icon.png`} alt=""/></div>
                            <div style={{minWidth: '16px'}}></div>
                            <div className="img"><img src={item.image_path} onError={(e)=>{e.target.onerror = null; e.target.src = `${Config.BASE_URL}/assets/img/alergic-icon.png`}} alt=""/></div>
                            <div style={{minWidth: '16px'}}></div>
                            <div className="info align-self-center">
                              <div className="courier-name">{item.name}</div>
                              <div className="courier-desc">Rp {new Intl.NumberFormat().format(item.total_price)}</div>
                            </div>
                          </div>
                        </FadeIn>
                      ))}
                    </div>
                  ))}
                </div>}
              </div>
            </div>
          </div>

          <div className="pay">
            <div className="container">
              <FadeIn><div className="d-flex justify-content-between">
                <div className="sub-label">Ongkir ({this.getDeliveryMethod().qty}x Pengiriman)</div>
                <div className="sub-price">Rp {new Intl.NumberFormat().format(this.getDeliveryMethod().price)}</div>
              </div></FadeIn>
              <div className="d-flex justify-content-between">
                <div className="label">Total Pembayaran:</div>
                <div className="price">Rp {new Intl.NumberFormat().format(this.getCartTotalPrice() - Config.calculateDiscount(this.props.user.coupon, this.getCartTotalPrice()) + this.getDeliveryMethod().price)}</div>
              </div>
              <div className="button">
                <button type="button">Pilih Metode Pengiriman</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  }
}, (dispatch) => {
  return {
    getCart: () => dispatch(getCart()),
    setCart: (data) => dispatch(setCart(data)),
    getAddress: () => dispatch(getAddress()),
    setAddress: (data) => dispatch(setAddress(data)),
    getCoupon: () => dispatch(getCoupon()),
  }
})(withRouter(DeliveryMethod));
